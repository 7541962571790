/**
 * 模块名称: 角色管理
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  message,
  Icon,
  Modal,
  //Switch,
  Drawer,
  Alert,
  Spin
} from 'antd'
import AddSonRole from './module/AddSonRole'
import Auth from '@/components/AuthMiddleware'
import { parseSearch, debounce } from '@/utils'
import { useSelector } from 'react-redux'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let filterParams = {
  limit: pageSize,
  page: currentPage
}
// let allCorps = []
let timeout = null

const RoleManage = (props) => {

  const { history, match } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields, getFieldValue } = props.form

  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [showDel, setShowDel] = useState(false)
  const [showForbidden, setShowForbidden] = useState(false)

  const [relatedRoleId, setRelatedRoleId] = useState('')
  const [relatedRoleIds, setRelatedRoleIds] = useState(0)
  const [statusTmp, setStatusTmp] = useState(null)

  const [roleTypeList, setRoleTypeList] = useState([])
  const [parentRoleTypeList, setParentRoleTypeList] = useState([])

  const [corpList, setCorpList] = useState([])
  // const [areaProjectList, setAreaProjectList] = useState([])
  const [sonRoleInfo, setSonRoleInfo] = useState({})
  const [drawerName, setDrawerName] = useState('添加子角色')
  const [drawerLoading, setDrawerLoading] = useState(true)
  const [showAddAssign, setShowAddAssign] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [parentMenuList, setParentMenuList] = useState([])
  const [childrenMenuList, setChildrenMenuList] = useState([])
  const [parentValue, setParentValue] = useState('')
  const [creatorList, setCreatorList] = useState([])

  const [authList, setAuthList] = useState([])//按钮权限
  const search = parseSearch(props.location.search)

  const menuList = useSelector(state => state.menuList)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filterParams = {
      limit: pageSize,
      page: currentPage
    }
    getRoleList()
    getPageAuth()
  }, [])
  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }
  //获取公司
  const getCorp = async (id, pid) => {
    // if (allCorps.length) {
    //   setCorpList(allCorps)
    //   setVisible(true)
    //   return
    // }
    // const res = await api.getCorp({limit: global.paramsLimit, projectIsOpen: 1})
    // const res = await api.getRoleModuleCompanyList({ limit: global.paramsLimit })
    let res = []
    if (pid == 0) {
      res = await api.getCompanyListByParentRoleId({ limit: global.paramsLimit, parentApprovalRoleId: id })
      
    } else {
      res = await api.getCompanyListByApprovalRoleId({ limit: global.paramsLimit, approvalRoleId: id })
    }
    /*res.forEach(item => {
      if(item.isValid === 1)list.push(item)
    })*/
    let arr = [].concat(res)
    arr.forEach((item, index, self) => {
      self[index].label = item.name
      self[index].value = item.id
    })
    setCorpList(arr)
    // allCorps = arr
    setVisible(true)
  }

  //项目范围
  /*const getAreaProject = () => {
    api.getCommon({type: 'sys_project'}).then((res) => {
      let arr = [].concat(res)
      arr.forEach((item, index, self) => {
        self[index].label = item.name
        self[index].value = item.id
      })
      setAreaProjectList(res)
    })
  }*/
  //搜索提交
  const searchSubmit = e => {
    e.preventDefault()
    let menuPid = getFieldValue('menuPid')
    let menuId = getFieldValue('menuId')
    if (menuPid && !menuId) return message.warning('请选择二级菜单后查询')
    filterParams.page = currentPage = 1
    validateFields((err, vals) => {
      const reg = /(^\s*)|(\s*$)/g
      setFieldsValue({
        keyword: vals.keyword ? vals.keyword.replace(reg, '') : ''
      })
      if (!err) {
        filterParams = { ...filterParams, ...vals }
        // console.log(filterParams)
        history.replace(match.path)
        getRoleList()
      }
    })
  }
  //重置
  const resetForm = () => {
    currentPage = 1
    filterParams = {
      limit: pageSize,
      page: currentPage
    }
    history.replace(match.path)
    resetFields()
    getRoleList()
  }
  //获取角色列表
  const getRoleList = () => {
    setLoading(true)
    for (let item in filterParams) {
      if (filterParams[item] === undefined || item == 'menuPid') delete filterParams[item]
    }
    api.getRoleList(filterParams).then(data => {
      data.list.forEach((item, index, arr) => {
        if (!item.operateAuth) item.operateAuth = []
        if (item.type === 'custom') {
          if (item.operateAuth.length) {
            arr[index].customDis = false
            arr[index].editDis = false
            arr[index].operateAuth.forEach(o => {
              if (o === 'role-disable-custom' || o === 'role-disable') {
                arr[index].customDis = true
              }
              if (o === 'role-edit-custom' || o === 'edit') {
                arr[index].editDis = true
              }
            })
          }
        } else {
          if (item.operateAuth.length) {
            arr[index].customDis = false
            arr[index].editDis = false
            arr[index].operateAuth.forEach(o => {
              if (o === 'role-disable') {
                arr[index].customDis = true
              }
              if (o === 'edit') {
                arr[index].editDis = true
              }
            })
          }
        }
      })
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }
  //跳转添加角色页面
  const onAddRole = () => {
    history.push('/authManagement/roleManage/addRole')
  }
  // 创建审批子角色
  const onSetShowAddAssign = () => {
    api.onAutoCreateApprovalChilds().then(() => {
      message.success('操作成功')
      setShowAddAssign(false)
    })
  }
  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    filterParams.page = currentPage = pagination.current
    filterParams.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getRoleList()
  }
  //点击编辑
  const onEdit = record => {
    if (record.pid === 0) {//如果是父角色
      history.push('/authManagement/roleManage/editRole', { id: record.id, name: record.name, desc: record.desc })
    } else {//如果是子角色
      setDrawerName('修改子角色')
      //setDrawerLoading(false)
      setSonRoleInfo(record)
      getCorp(record.id, record.pid)
      // getAreaProject()
    }
  }
  //根据角色ID获取关联的员工数量(删除)
  const onGetStaffListCount = id => {
    setRelatedRoleId(id)
    api.getRoleGetStaffListCountByRoleId({ roleId: id }).then(res => {
      setRelatedRoleIds(res.count)
      setShowDel(true)
    })
  }
  //点击删除
  const onDelete = () => {
    api.getRoleDelete({ id: relatedRoleId }, true).then(() => {
      message.success('删除成功')
      setShowDel(false)
      getRoleList()
    })
  }
  //根据角色ID获取关联的员工数量(禁用)
  const onGetStaffListCountFob = id => {
    api.getRoleGetStaffListCountByRoleId({ roleId: id }).then(res => {
      setRelatedRoleIds(res.count)
      setShowForbidden(true)
    })
  }
  //设置状态是否可用
  const setStatusEnable = (id, val) => {
    setRelatedRoleId(id)
    setStatusTmp(val)
    if (val == 0) {//eslint-disable-line
      onGetStaffListCountFob(id)
    } else {
      api.setRoleEnabled({ id: Number(id), value: Number(val) }, true).then(() => {
        message.success('操作成功')
        setShowForbidden(false)
        getRoleList()
      })
    }
  }
  //修改角色状态为可用
  const onChangeStatus = () => {
    api.setRoleEnabled({ id: Number(relatedRoleId), value: Number(statusTmp) }, true).then(() => {
      message.success('操作成功')
      setShowForbidden(false)
      getRoleList()
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }
  //获取角色类型列表
  const getRoleTypeList = () => {
    api.getRoleTypeList().then((res) => {
      setRoleTypeList(res.list)
    })
  }
  //获取父级角色类型列表
  const getParentRoleList = () => {
    api.getParentRoleList().then((res) => {
      setParentRoleTypeList(res.list)
    })
  }
  //获取创建人列表
  const getStfNameList = name => {
    setFieldsValue({ 'creator': undefined })
    setCreatorList([])
    if (!name) return
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      api.getCreatorList({ name }).then(res => {
        setCreatorList(res)
      })
    }, 1000)
  }
  //获取一级菜单列表
  const getParentMenuList = () => {
    setParentMenuList(menuList)
  }
  //获取选中的一级菜单
  const getParentMenuValue = val => {
    setParentValue(val)
    setChildrenMenuList([])
    setFieldsValue({
      'menuId': undefined
    })
  }
  //获取二级菜单列表
  const getChildrenMenuList = () => {
    // console.log(parentValue)
    menuList.forEach(item => {
      if (item.id == parentValue) {
        setChildrenMenuList(item.children)
      }
    })
  }
  //打开抽屉弹窗
  const openDrawer = record => {
    record.pid === 0 ? setDrawerName('添加子角色') : setDrawerName('修改子角色')
    setVisible(true)
    setSonRoleInfo(record)
    getCorp(record.id, record.pid)
    // getAreaProject()
    let timer = setTimeout(() => {
      setDrawerLoading(false)
      clearTimeout(timer)
    }, 1500)
  }
  //关闭抽屉弹窗
  const closeDrawer = () => {
    setVisible(false)
  }

  return (
    <>
      <div className="search-role-wrap">
        <h4 className="title">&nbsp;</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout} >
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="角色名/ID">
              {getFieldDecorator('keyword')(<Input
                placeholder="角色名称或者ID"
              />)}
            </FormItem>
            <FormItem label="角色类型">
              {getFieldDecorator('type')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getRoleTypeList()}
              >
                {
                  roleTypeList.map((item, index) => {
                    return <Option key={item.name}>{item.value}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="父级角色">
              {getFieldDecorator('pid')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getParentRoleList()}
                optionFilterProp="children"
                showSearch
                dropdownMatchSelectWidth={false}
              >
                {
                  parentRoleTypeList.map(item => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="创建人">
              {getFieldDecorator('creator')(<Select
                placeholder="全部"
                // onDropdownVisibleChange={bool => bool && getStfNameList()}
                onSearch={getStfNameList}
                optionFilterProp="children"
                showSearch
                notFoundContent={null}
              >
                {
                  creatorList.map(item => {
                    return <Option key={item.id}>{item.staffName}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="一级导航菜单">
              {getFieldDecorator('menuPid')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getParentMenuList()}
                onChange={getParentMenuValue}
                optionFilterProp="children"
                showSearch
              >
                {
                  parentMenuList.map((item, index) => {
                    return <Option key={item.id}>{item.title}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="二级导航菜单">
              {getFieldDecorator('menuId')(<Select
                placeholder={!getFieldValue('menuPid') ? '请先选择一级导航菜单' : '全部'}
                onDropdownVisibleChange={bool => bool && getChildrenMenuList()}
                optionFilterProp="children"
                showSearch
                disabled={!getFieldValue('menuPid')}
              >
                {
                  childrenMenuList.map((item, index) => {
                    return <Option key={item.id}>{item.title}</Option>
                  })
                }
              </Select>)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <Button type="primary" htmlType="submit">查询</Button>
            <Button onClick={resetForm}>重置</Button>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        {/* <Button style={{marginRight: '20px'}} type="primary" title="创建子角色" icon="plus" onClick={() => setShowAddAssign(true)}>创建子角色</Button> */}
        <Auth auths={authList} code="add">
          <Button type="primary" title="添加角色" icon="plus" onClick={onAddRole}>添加角色</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1400 }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" fixed="left" width={80} />
        <Column title="角色名称" dataIndex="name" />
        <Column title="角色类型" dataIndex="typeName" />
        <Column title="角色说明" dataIndex="desc" />
        <Column title="父级角色" dataIndex="parantRoleName" />
        <Column title="创建人" dataIndex="stfName" />
        <Column title="状态" key="isValidCn" render={(text, record) => (
          <>
            {/* {
              text.customDis ? <Switch size="small" defaultChecked={text.status === 1} onClick={val => onGetStaffListCountFob(text.id, val)} /> : '' 
            } */}
            {
              text.customDis && text.status === 1 && <button onClick={() => setStatusEnable(text.id, 0)} type="button" role="switch" aria-checked="true" className="ant-switch-small ant-switch ant-switch-checked"><span className="ant-switch-inner"></span></button>
            }
            {
              text.customDis && text.status === 0 && <button onClick={() => setStatusEnable(text.id, 1)} type="button" role="switch" aria-checked="false" className="ant-switch-small ant-switch"><span className="ant-switch-inner"></span></button>
            }
          </>
        )

        } />
        <Column title="操作" key="set" fixed="right" width={120} render={(text, record) => (
          <>
            {
              // (record.type !== "approval" || (record.type === "approval" && record.pid !== 0)) && text.editDis ? <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} /> : '' 
              record.type !== "approval" && text.editDis ? <Icon className="operate-icon" style={{ color: '#1890ff' }} type="edit" onClick={() => onEdit(record)} /> : ''
            }
            {/* 审批角色的父角色有权限添加和修改子角色,审批角色的子角色只能编辑自己创建的角色 */}
            <Auth auths={text.operateAuth} code="role-add-edit-child">
              {
                record.type === "approval" && <Icon className="operate-icon" type={record.pid !== 0 ? "edit" : "user-add"} onClick={() => openDrawer(record)} />
              }
            </Auth>
            <Auth auths={text.operateAuth} code="delete">
              {
                (record.type !== "approval" || (record.type === "approval" && record.pid !== 0)) && <Icon className="operate-icon" style={{ color: 'red' }} type="delete" onClick={() => onGetStaffListCount(record.id)} />
              }
            </Auth>
          </>
        )} />
      </Table>
      <Modal
        title="确认删除"
        visible={showDel}
        onOk={onDelete}
        onCancel={debounce(() => setShowDel(false), 1000)}
      >
        <p>该角色当前有{relatedRoleIds}人使用，是否删除？</p>
      </Modal>
      <Modal
        title="确认禁用"
        visible={showForbidden}
        onOk={onChangeStatus}
        onCancel={debounce(() => setShowForbidden(false), 1000)}
      >
        <p>该角色当前有{relatedRoleIds}人使用，是否禁用？</p>
      </Modal>
      <Modal
        title="提示"
        visible={showAddAssign}
        onOk={debounce(() => onSetShowAddAssign(), 1000)}
        onCancel={() => setShowAddAssign(false)}
      >
        <p>确认批量创建父级子角色？</p>
      </Modal>
      <Drawer
        title={drawerName}
        width={800}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <AddSonRole
            corpList={corpList}
            setVisible={setVisible}
            sonRoleInfo={sonRoleInfo}
            getRoleList={getRoleList}
            setDrawerLoading={setDrawerLoading}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(RoleManage)