/**
 * 模块名称: 考勤管理
 * @author xuzhongyuan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import './assets/style.scss'
import Workday from './Workday'
import Askforleave from './Askforleave'
import AddaskDetail from './module/AddaskDetail'
import AddaskDetails from './module/AddaskDetails'
import Whole from './Whole'
import Statistics from './Statistics'
import Appeal from './Appeals'
import Selfwork from './Selfwork'

const Attendance = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/workday'} component={Workday} />
      <Route path={match.path + '/askforleave'} exact component={Askforleave} />
      <Route path={match.path + '/askforleave/addaskdetail'} component={AddaskDetail} />
      <Route path={match.path + '/askforleave/addaskdetails'} component={AddaskDetails} />
      <Route path={match.path + '/whole'} component={Whole} />
      <Route path={match.path + '/statistics'} component={Statistics} />
      <Route path={match.path + '/appeal'} component={Appeal} />
      <Route path={match.path + '/individual'} component={Selfwork} />
    </Switch>
  )
}

export default Attendance