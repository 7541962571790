/**
 * 模块名称: 分配角色
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Form,
	Input,
	Checkbox,
	Button,
	Select,
	Row,
	Col,
	// Tag,
	message
} from 'antd'

const FormItem = Form.Item
const Option = Select.Option
let disabled = true

const AssignRoles = (props) => {

	const { getFieldDecorator, validateFields } = props.form

	const [roleTypeList, setRoleTypeList] = useState([])
	const [roleList, setRoleList] = useState([])
	const [hasRangedRolesId, setHasRangedRolesId] = useState([])
	const [roleId, setRoleId] = useState('')

	const {
		routee,
		setVisible,
		sId,
		setUseId,
		getStaffRoleList,
		roleType,
		setRoleType,
		setDrawerLoading,
		jobIds,
		depIds,
		openBy,
		setSelectedRowKeysData,
		opType
	} = props

	useEffect(() => {
		disabled = true
		getRoleList()
		getRolesById()
	}, [])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}
	const formItemLayout2 = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 0 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 24 },
		}
	}

	//抽屉获取角色列表
	const getRoleList = () => {
		setDrawerLoading(true)
		api.getRoleList({
			keyword: roleId,
			type: roleType === '全部' ? 'all' : roleType
		}).then(data => {
			setRoleList(data.list)
			setDrawerLoading(false)
		}).catch(() => setDrawerLoading(false))
	}

	//抽屉根据员工id获取分配的角色列表/或者=>根据部门id和职位id获取分配的角色
	const getRolesById = () => {
		//console.log(props.jobId, jobIds, depIds)
		if (routee === 'job') {
			const { jobId } = props
			let params = {}
			if (openBy == 'single' && jobId !== null) {
				params = {
					departmentId: sId,
					jobId: jobId
				}
				api.getRolesByDepartmentIdAndJobId(params).then(res => {
					let arr = [], list = res.list

					list.forEach(item => {
						item && arr.push(item.id)
					})
					setHasRangedRolesId(arr)
				})
			}
			/*if(openBy == 'multiple' && jobIds.length && depIds.length){
				params = {
					mode: 'intersection',
					departmentId: depIds.join(','),
					jobId: jobIds.join(',')
				}
			}*/
		} else {
			api.getRolesByStaffId({ staffId: sId }).then(res => {
				let arr = [], list = res.list

				list.forEach(item => {
					item && arr.push(item.id)
				})
				setHasRangedRolesId(arr)
			})
		}
	}

	//获取角色类型
	const getRoleTypeList = () => {
		api.getRoleTypeList().then((res) => {
			setRoleTypeList(res.list)
		})
	}

	//点击筛选
	const filterAssignRoles = () => {
		getRoleList()
	}

	//更改角色名称（id）
	const changeRoleName = (e) => {
		e.persist()
		const reg = /(^\s*)|(\s*$)/g
		setRoleId(e.target.value.replace(reg, ''))
	}

	//更改角色类型
	const changeRoleTypeList = (e) => {
		setRoleType(e)
	}
	//修改角色列表选中的角色
	const onChangeRolist = (e) => {
		let data = e.target
		if (hasRangedRolesId.length) {
			for (let i = 0; i < hasRangedRolesId.length; i++) {
				if (hasRangedRolesId[i] == data.value) {//eslint-disable-line
					hasRangedRolesId.splice(i, 1)
					i--
				}
			}
		}
		setHasRangedRolesId(hasRangedRolesId)
	}

	//重置表单
	const resetForm = () => {
		setRoleType('全部')
		setUseId(0)
		if (routee === 'job') {
			const { setDeptId } = props
			setDeptId(0)
		}
		setVisible(false)
	}

	//重置筛选
	const resetFilter = () => {
		setRoleId('')
		setRoleType('全部')
	}

	//保存分配的角色 提交
	const searchSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				let arr = vals.roleIds.concat(hasRangedRolesId)
				bindingRolesForStaff([...new Set(arr)])
				// getStaffRoleList()
			}
		})
	}

	//个人授权提交
	const bindingRolesForStaff = roleIds => {
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.error('请不要频繁点击')			
		}
		let str = roleIds.join(',')
		//
		if (routee === 'job') {
			const { jobId } = props
			let paramsForJob = {}
			//如果是从授权按钮进来
			if (openBy == 'multiple' && jobIds.length && depIds.length) {
				paramsForJob = {
					departmentId: depIds.join(','),
					jobId: jobIds.join(','),
					roleIds: str,
					mode: 'batch',
					opType: opType
				}
			} else {
				paramsForJob = {
					departmentId: sId,
					jobId: jobId,
					roleIds: str
				}
			}
			// console.log('paramsForJob=>', paramsForJob)
			api.onBindingRolesForJob(paramsForJob).then(res => {
				if (res) {
					setVisible(false)
					setRoleType('全部')
					getStaffRoleList()
					if (opType == 'sub') {
						message.success('取消授权成功')
					} else {
						message.success('授权成功')
					}
					setSelectedRowKeysData([])
				}
			})
		} else {
			let paramsForStaff = {
				staffId: sId,
				roleIds: str
			}
			api.onBindingRolesForStaff(paramsForStaff).then(res => {
				if (res) {
					setVisible(false)
					setRoleType('全部')
					getStaffRoleList()
					message.success('授权成功')
				}
			})
		}
	}

	return (
		<>
			<div className="assign-roles-wrap">
				<Form {...formItemLayout} layout="inline" >
					<FormItem label="角色名/ID">
						{<Input placeholder="角色名称或者ID" onPressEnter={filterAssignRoles} value={roleId} onChange={changeRoleName} />}
					</FormItem>
					<FormItem label="角色类型">
						{<Select
							placeholder="全部"
							onDropdownVisibleChange={bool => bool && getRoleTypeList()}
							onChange={changeRoleTypeList}
							value={roleType}
						>
							{
								roleTypeList.length && roleTypeList.map((item, index) => {
									return <Option key={item.name}>{item.value}</Option>
								})
							}
						</Select>}
					</FormItem>
					<FormItem className="btns assign-roles-filter" label=" " colon={false}>
						<Button onClick={resetFilter}>重置</Button>
						<Button className="submit" type="primary" htmlType="button" onClick={filterAssignRoles}>筛选</Button>
					</FormItem>
				</Form>
				<Form onSubmit={searchSubmit} {...formItemLayout2}>
					<FormItem className="btns assign-roles-checkbox" label=" " colon={false}>
						{getFieldDecorator('roleIds', { initialValue: hasRangedRolesId })(
							<Checkbox.Group style={{ width: '100%' }}
							>
								<Row>
									{
										roleList.map((item) => {
											return <Col span={12} key={item.id}>
												<Checkbox value={item.id} disabled={item.status === 0 ? true : false} onChange={onChangeRolist}>{
													<div className="role-tag" style={{ width: 230 }}>
														<span>{item.name}</span>
														{/* <Tag style={item.status === 0 ? {color: "rgba(0, 0, 0, 0.25)"} : {color: "rgba(0, 0, 0, 0.65)"}}>{item.typeName}</Tag> */}
														<br></br>
														<span>{item.desc.length ? item.desc : item.name + "角色"}</span>
													</div>
												}</Checkbox>
											</Col>
										})
									}
								</Row>
							</Checkbox.Group>)}
					</FormItem>
					<FormItem className="btns assign-roles-btns" label=" " colon={false}>
						<Button onClick={resetForm}>取消</Button>
						<Button className="submit" type="primary" htmlType="submit">确定</Button>
					</FormItem>
				</Form>
			</div>
		</>
	)
}

export default Form.create()(AssignRoles)