/**
 * 模块名称: 请假外出 => 请假外出申请单详情（育儿假审批详情）
 * @author liujingxue@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from 'api'
import {
  Steps,
  Descriptions,
  Button,
  Modal,
  Input,
  Icon,
  message,
  Spin,
  Table
} from 'antd'
import { parseSearch, debounce } from 'utils'
import CryptoJS from 'crypto-js'

const { Step } = Steps
const dItem = Descriptions.item
const { Column } = Table

let areaTxt = '', result = ''

const AddaskDetails = (props) => {

  const { history, location } = props

  const [detail, setDetail] = useState({})
  const [detailStafInfo, setDetailStafInfo] = useState({})
  const [flow, setFlow] = useState([])
  const [remark, setRemark] = useState([])
  const [affixList, setAffixList] = useState([])
  const [affixList1, setAffixList1] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [pageId, setPageId] = useState('')
  const [auditRecord, setAuditRecord] = useState([])
  const [loading, setLoading] = useState(true)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [attList, setAttList] = useState([])

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
  const search = parseSearch(props.location.search)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      areaTxt = ''
      result = ''
      if (location.search) {
        setPageId(parseSearch(location.search).id)
        getLeaveOutRow(parseSearch(location.search).id)
      } else {
        history.push({
          pathname: '/attendance/askforleave'
        })
      }
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])
  //获取日常办公审批单条的详情
  const getLeaveOutRow = (id) => {
    api.getLeaveoutYuerDetail({ id: id }).then(res => {
      if (parseSearch(location.search).msgId) {
        api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      if (JSON.stringify(res) !== "[]") {
        let data = Object.assign({}, res)
        if (data.status === 0) data.current = 0
        if (data.status === 1) data.current = 1
        if (data.status === 2) data.current = 2
        if (data.status === 3) data.current = 3
        setDetail(data.info)
        setDetailStafInfo(data.info.stafInfo)
        setAttList(data.info.cycle)
        let auditFlow = data.auditStatus
        auditFlow.forEach((item, index, self) => {
          if (item.persons && item.persons.length) {
            self[index].persons = item.persons.join('，')
          }
        })
        setFlow(auditFlow)
        if (data.info.affix) {
          let affix = JSON.parse(data.info.affix)
          setAffixList(affix)
        }
        if (data.info.affix1) {
          let affix1 = JSON.parse(data.info.affix1)
          setAffixList1(affix1)
        }
        
        setAuditRecord(data.auditHistory)
        if (data.info.remark.length) {
          setRemark(data.info.remark)
        } else {
          setRemark('无')
        }
        if (data.auditButton && JSON.stringify(data.auditButton) !== '[]') {
          setAuditAuth(true)
          setHasChargerPower(data.auditButton.hasChargerPower)
          setReplenish(data.auditButton.replenish)
          setReturnUp(data.auditButton.return_up)
          setRejectMsgRequire(data.auditButton.reject_msg_require)
        } else {
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
          setReturnUp(false)
          setRejectMsgRequire(false)
        }
      } else {
        setDetail({})
        setDetailStafInfo({})
        setFlow([])
        setAffixList([])
        setAffixList1([])
        setAuditRecord([])
      }
      setLoading(false)
    }).catch((err) => {
      if (err.code === 200404) {
        setTimeout(() => {
          history.goBack()
        }, 1500)
        return
      }
    })
  }
  //点击4个审批的按钮
  const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    if (no == 0) {//eslint-disable-line
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else if (no == 1) {//eslint-disable-line
      //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:23
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else {
      api.setLeaveoutYuerAudit({
        id: pageId,
        type: result,
        remark: areaTxt
      }).then(res => {
        //todo 转上级或者补充资料 返回列表页
        let timer = setTimeout(() => {
          history.push({
            pathname: '/attendance/askforleave'
          })
          clearTimeout(timer)
        }, 1000)
      })
    }
  }
  const oprateHandleOk = () => {
    if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
      message.warning('请输入至少1个字符')
      return
    }
    let params = {
      id: pageId,
      type: result,
      remark: areaTxt
    }
    api.setLeaveoutYuerAudit(params).then(res => {
      message.success('审批成功')
      setModalVisible(false)
      getLeaveOutRow(pageId)
    })
  }
  //取消弹窗
  const oprateHandleCancel = () => {
    setModalVisible(false)
  }
  //同意的样式
  const agreeNode = () => {
    return result === 0 ? <>
      同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </> : <>
      不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
    </>
  }
  //审批意见
  const getAreaTxt = (e) => {
    areaTxt = e.target.value.trim()
  }

  //退回列表页
  const onGoBack = () => {
    history.push({ pathname: '/attendance/askforleave', state: { goBack: 1 } })
  }

  return (
    <Spin spinning={loading}>
      {
        parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
          position: 'absolute',
          right: '20px',
          top: 0
        }}>返回上一页</Button>
      }
      <div className="add-newask-detail">
        <Descriptions title="育儿假资格申请单" layout="horizontal" className="pro-detail-title" style={{ borderBottom: 'none' }}>
          {detailStafInfo.staffName && <dItem label="申请人">{`${detailStafInfo.staffName}（ID：${detailStafInfo.staffNo}）`}</dItem>}
        </Descriptions>
        <div className="blank-line"></div>
        <div className="step-wrap">
          <div className="record-list-title">当前审批进度</div>
          <Steps current={detail.current}>
            {
              flow.map((item, index, self) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status} title={item.nodeName} key={index} description={
                  <>
                    {item.persons && <div title={item.persons}>{item.persons}</div>}
                    <div title={item.statusName}>{item.statusName}</div>
                    <div title={item.time}>{item.time}</div>
                  </>
                } />
              })
            }
          </Steps>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">申请信息</div>
          <Descriptions layout="vertical" className="pro-detail-title" column={3} style={{ borderBottom: "none" }}>
            <dItem label="姓名">{detailStafInfo.staffName}</dItem>
            <dItem label="所属公司">{detailStafInfo.companyName}</dItem>
            <dItem label="所属部门">{detailStafInfo.departmentName}</dItem>
            <dItem label="职位">{detailStafInfo.jobPositionCn}</dItem>
            <dItem label="直接主管">{detailStafInfo.leaderName}</dItem>
            <dItem label="员工编号">{detailStafInfo.staffNo}</dItem>
            <dItem label="请假类型">{detail.askType}</dItem>
            <dItem label="子女出生日期">{detail.date1}</dItem>
          </Descriptions>
        </div>
        <div className="table-wrap">
          <div className="table-wrap-title">育儿假信息</div>
          <Table
            dataSource={attList}
            rowKey="id"
            className="today-info"
            pagination={false}
          >
            <Column title="育儿假周期" dataIndex="show" />
            <Column title="可用时长" dataIndex="usable" />
            <Column title="已用时长" dataIndex="used" />
            <Column title="状态" dataIndex="status" />
          </Table>
        </div>
        <Descriptions title="结婚证附件"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
          <div className="content">
            {
              affixList.length ? affixList.map((item, index) => {
                return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : '无'
            }
          </div>
        </Descriptions>
        <Descriptions title="子女出生证明附件"
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
          <div className="content">
            {
              affixList1.length ? affixList1.map((item, index) => {
                return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
              }) : '无'
            }
          </div>
        </Descriptions>
        <Descriptions title="备注" 
          layout="horizontal"
          className="pro-detail-smailtitle"
        >
          <div className="content">
            {remark}
          </div>
        </Descriptions>
        <div className="blank-line"></div>
        <div className="record-list">
          <div className="record-list-title">审批记录</div>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
            <dItem label="姓名"></dItem>
            <dItem label="时间"></dItem>
            <dItem label="状态"></dItem>
            <dItem label="审批意见"></dItem>
          </Descriptions>
          {
            auditRecord.map((item, index) => {
              return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.userInfo.staffName}</dItem>
                <dItem label="">{item.addTime}</dItem>
                <dItem label="">{item.status}</dItem>
                <dItem label="">{item.chargeMsg}</dItem>
              </Descriptions>
            }) 
          }
        </div>
        {
          auditAuth && <div className="btns">
            {replenish && <Button onClick={debounce(() => { leaveOutAudit(3) }, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => { leaveOutAudit(2) }, 3000)} className="orange-btn">转上级</Button>}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
          </div>
        }
        <Modal
          title={agreeNode()}
          visible={modalVisible}
          onOk={debounce(() => { oprateHandleOk() }, 3000)}
          onCancel={oprateHandleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={oprateHandleCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={debounce(() => { oprateHandleOk() }, 3000)}>确定</Button>
          ]}
        >
          <div className="officework-modal-content">
            <span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
          </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default AddaskDetails
