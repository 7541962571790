/**
 * 模块名称: 请假外出 -> 请假申请
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import api from 'api'
import {
	Form,
	Input,
	InputNumber,
	Select,
	Upload,
	message,
	Icon,
	Row,
	Col,
	DatePicker,
	Tooltip,
	Table,
	Modal,
	Button,
	TimePicker,
	Radio
} from 'antd'
import BtnGroup from 'components/BtnGroup'
import moment from 'moment'
import { upLoadModule } from 'utils/common'

const FormItem = Form.Item
const Option = Select.Option
const { Dragger } = Upload
const { Column } = Table
let disabled = true

const Addnewask = (props) => {
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

	const { setVisible, getLeaveOutList, setDrawerLoading, addAskType, editId } = props

	const [fileList, setFileList] = useState([])
	const [fileList2, setFileList2] = useState([])//上传结婚证
	const [fileList3, setFileList3] = useState([])//上传子女出生证明
	const [saveLoading, setSaveLoading] = useState(false)
	const [typeList, setTypeList] = useState([])

	const [ifChange7, setIfchange7] = useState(false)
	const [ifChange3, setIfchange3] = useState(false)
	const [longDis, setLongDis] = useState(false)
	const [max, setMax] = useState(15)
	const [min, setMin] = useState(0.5)
	const [timesDis, setTimesDis] = useState(false)
	const [attList, setAttList] = useState([])
	const [askTip, setAskTip] = useState('剩余假期不足')
	const [askType, setAskType] = useState('')//请假类型
	// const [askType, setAskType] = useState(false)
	const [wkDayYes, setWkDayYes] = useState(null)
	const [njDayYes, setNjWkDayYes] = useState(null)
	const [ifChange4, setIfchange4] = useState(false)
	const [ifChange12, setIfchange12] = useState(false)	
	const [stepVal, setStepVal] = useState(0.5)
	const [askDisabled, setAskDisabled] = useState(false)
	const [cDateTime, setcDateTime] = useState(null) // 当前日期
	const [sDateTime, setsDateTime] = useState(null) // 起始日期
	const userInfo = useSelector(state => state.userInfo)
	const [modalFlag, setModalFlag] = useState(false)
	const [weekVal, setWeekVal] = useState(0)
	const [forgetModal, setForgetModal] = useState(false)
	const [forgetData, setForgetData] = useState('')
	const [reminderModal, setReminderModal] = useState(false)
	const [askInitLong, setAskInitLong] = useState(null) // 起始时长

	const draggerRef = useRef(null)
	const draggerRef2 = useRef(null)//上传结婚证
	const draggerRef3 = useRef(null)//上传子女出生证明

	useEffect(() => {
		disabled = true
		initData()
		timeBarData()
	}, [])

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 4 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 20 },
		}
	}
	const timeBarData = () => {
		api.getWorkDayLastWorkDay().then(res => res && setWkDayYes(res)).catch(() => message.warning('获取工作日失败'))
		api.getAnualLeaveSelectDate().then(res => res && setNjWkDayYes(res)).catch(() => message.warning('获取工作日失败'))
	}
	//不可选择的日期
	const disabledDate = (current) => {
		//编辑时 起始日期值
		if (addAskType == 1) {
			if (askType == 3) { // 病假需要限制结束范围
				return sDateTime < cDateTime ? 
				current && moment().endOf('year') < current || current < moment(sDateTime).subtract(0, 'days') : 
				current && moment().endOf('year') < current || current < moment(cDateTime).subtract(0, 'days')
			} else {
				return sDateTime < cDateTime ? 
				current && current < moment(sDateTime).subtract(0, 'days') : 
				current && current < moment(cDateTime).subtract(0, 'days')
			}
		} else {
			// 申请时
			// return current && current < moment().endOf('day')
			if (ifChange7) {
				// return current && current < moment().subtract(2, 'days')
				return current && current < moment(wkDayYes).subtract(0, 'days')
			} else if (ifChange4) {
				return current && current < moment(njDayYes).subtract(0, 'days')
			} else if (ifChange3) {
				return current && moment().endOf('year') < current || current < moment().subtract(1, 'days')
			} else if (ifChange12) {
				return current && current < moment().subtract(1, 'year').subtract(1, 'days')
			} else {
				return current && current < moment().subtract(1, 'days')
			}
		}
	}

	//育儿假不可选择的日期
	const disabledDates = (current) => {
		// console.log(current)
		// console.log(wkDayYes)
		// return current && current < moment().endOf('day')
		return current && current > moment().subtract(0, 'days')
	}

	// 妊娠终止不可选择的日期
	const endTimeDate = (current) => {
		return current && current < moment(getFieldValue('date1')).subtract(0, 'days')
	}

	const onChangeWeek = (date1, date2) => {
		if (date1 !== undefined && date2 !== undefined) {
			let timeSub = (moment(date2) - moment(date1))
			// console.log("周数", parseInt(timeSub / (1000*7*24*60*60)))
			// console.log("天数", parseInt(timeSub / (1000 * 60 * 60 * 24)))
			setWeekVal(parseInt(timeSub / (1000 * 7 * 24 * 60 * 60)))
			setFieldsValue({
				long: parseInt(timeSub / (1000 * 7 * 24 * 60 * 60)) < 16 ? 15 : 42
			})
			getLeaveOutEndDate(getFieldValue('ask'), getFieldValue('sTime'), getFieldValue('sDate') ? moment(getFieldValue('sDate')).format('YYYY-MM-DD') : undefined, getFieldValue('long'))
		}
	}

	//初始化 拿到请假类型、当天考勤信息
	const initData = () => {
		//获取请假类型
		getTypeList()
		setDrawerLoading(true)
		//不是编辑详情
		if (addAskType == 0) {//eslint-disable-line
			getLeaveOutRecentSign()
			let timer = setTimeout(() => {
				setDrawerLoading(false)
				clearTimeout(timer)
			}, 500)
		}
		//编辑详情
		if (addAskType == 1) {//eslint-disable-line8
			api.getLeaveOutRow({ id: editId, type: 1 }).then(res => {
				setAskType(res.ask)
				setcDateTime(res.cDate)
				setsDateTime(res.sDate)
				setAskInitLong(res.long)
				//console.log(res)
				if (res.ask == 13) {
					setAskDisabled(true)
					api.getLeaveoutYuerDetail({ id: editId }).then(res => {
						setTimeout(() => {
							setFieldsValue({
								ask: res.info.ask ? res.info.ask : undefined,
								date1: res.info.date1 ? moment(res.info.date1) : undefined,
								affix: res.info.affix ? res.info.affix : undefined,
								affix1: res.info.affix1 ? res.info.affix1 : undefined,
								remark: res.info.remark ? res.info.remark : undefined
							})
							
						}, 1000)
						if (res.info.affix) {
							let affix = JSON.parse(res.info.affix)
							affix.forEach((item, index) => {
								fileList2.push({
									status: 'done',
									uid: index,
									name: item.name,
									url: item.url
								})
							})
							setFileList2(affix.map((item, index) => ({ ...item, uid: index })))
						}
						if (res.info.affix1) {
							let affix1 = JSON.parse(res.info.affix1)
							affix1.forEach((item, index) => {
								fileList3.push({
									status: 'done',
									uid: index,
									name: item.name,
									url: item.url
								})
							})
							setFileList3(affix1.map((item, index) => ({ ...item, uid: index })))
						}
						setAttList(res.info.cycle)
					})
				} else {
					setTimeout(() => {
		                // 起始时间为下午时，请假时长应默认禁用（接口有提示，前端展示优化）
						const { sTime, ask } = res
						if (sTime && sTime.includes('下午')) {
							if (ask === 1 || ask === 2 || ask === 3 || ask === 6 || ask === 7 || ask === 4) {
								setLongDis(true)
							}
						}
						// 优化 请假类型有固定时间，编辑需禁用，否则切换下午时获取结束日期错误
						if (ask == 5 || ask == 10 || ask == 11 || ask == 16) {
							setLongDis(true)
							setTimesDis(true)
						}
						// 哺乳假特殊处理，有固定时间，但可切换起始时间
						// 编辑时，产假9、哺乳假12, long > 15天 需要设置最大值
						if (ask == 12) {
							setLongDis(true)
							setMax(365)
						}
						if (ask == 9) {
							setTimesDis(true)
							setMax(200)
							setMin(1)
						}
						if (ask == 15) {
							// setTimesDis(true)
							setMax(365)
							setMin(1)
						}
						if (ask == 16) {
							setMax(42)
						}
						// 编辑时 获取剩余假期天数（年假和事假）
						if (res.ask == 14) {
							setTimesDis(true)
							setMin(1)
						}
						if (res.ask == 2 || res.ask == 14) {
							api.getLeaveOutAskRemainNum({ ask: res.ask }).then(res => {
								if (res.num !== 0.5) {
									setMax(Math.floor(res.num))
								} else {
									setMax(res.num)
								}
							})
						}
						if (res.ask == 4) {
							api.getLeaveOutAskRemainNum({ ask: res.ask }).then(data => {
								if (data.num !== 0.5) {
									setMax(res.long < data.num ? res.long : Math.floor(data.num))
								} else {
									setMax(res.long < data.num ? res.long : data.num)
								}
							})
						}
						if (res.ask == 16) {
							setFieldsValue({
								date1: res.date1 ? moment(res.date1) : undefined,
								date2: res.date2 ? moment(res.date2) : undefined,
							})
							setWeekVal(+res.pregnancyCycle)
						}
						setFieldsValue({
							ask: res.ask ? res.ask : undefined,
							sDate: res.sDate ? moment(res.sDate) : undefined,
							eDate: res.eDate ? res.eDate : undefined,
							sTime: res.sTime ? res.ask == 7 ? res.sTime : res.sTime.split(' ')[0] : undefined,
							long: res.long ? res.long : 0.5,
							remark: res.remark ? res.remark : undefined
						})
						setStepVal(res.long ? res.long : 0.5)

						if (res.ask == 7) {
							setFieldsValue({
								punchInAddress: res.punchInAddress && res.punchInAddress == "居家" ? 2 : 1 ,
								punchInTime: res.punchInTime ? moment(res.punchInTime, 'HH:mm') : undefined,
							})
						}
					}, 1000)
					if (res.affix.length) {
						res.affix.forEach((item, index) => {
							fileList.push({
								status: 'done',
								uid: index,
								name: item.name,
								url: item.url
							})
						})
						setFileList(res.affix.map((item, index) => ({ ...item, uid: index })))
					}
					setAttList(res.checkInData)
				}
				setTimeout(() => {
					setDrawerLoading(false)
				}, 1000)
			})
		}
	}
	//获取请假列表
	const getTypeList = () => {
		api.getLeaveOutAskType({operation: editId ?  '' : 'apply', source: 1 }).then(res => {
			setTypeList(res.list)
		})
	}
	//获取最近考勤列表
	const getLeaveOutRecentSign = () => {
		api.getLeaveOutRecentSign({
			sDate: moment().format('YYYY-MM-DD')
		}).then(res => {
			setAttList(res)
		})
	}
	//获取剩余假期天数（年假和事假）
	const getLeaveOutAskRemainNum = (id) => {
		api.getLeaveOutAskRemainNum({ ask: id }).then(res => {
			if (addAskType == 1 && id == 4) {// 编辑切换年假时最大值不可超过申请时长
				if (res.num !== 0.5) {
					setMax(askInitLong < res.num ? askInitLong : Math.floor(res.num))
				} else {
					setMax(askInitLong < res.num ? askInitLong : res.num)
				}
			} else {
				if (res.num !== 0.5) {
					setMax(Math.floor(res.num))
				} else {
					setMax(res.num)
				}
			}
			
			if (res.num > 0 && id !== 14) {
				setMin(0.5)
			}
		}).catch(res => {
			setMin(0)
			setLongDis(true)
			setAskTip(res.msg)
			setFieldsValue({
				long: 0
			})
		})
	}
	//更改请假类型
	const onChangeType = (e) => {
		// setFileList([])
		setFileList2([])
		setFileList3([])
		setWeekVal(0)
		setAskType(e)
		if (e !== 13) {
			//更改请假类型将初始化起始日期
			setFieldsValue({
				// sDate: undefined,
				sTime: undefined // 忘打卡和其它类型进行切换需要清空
			})
			if (addAskType == 0) {
				setFieldsValue({
					sDate: undefined, // 申请时先选时间在选类型，起始日期需要清空
				})
			}
			//忘打卡：请假类型为忘打卡时可以选择日期最早为昨天。请假类型为其它时，起始日期仅能选择今天及以后日期
			//年假：16点之后不可请次日的
			//病假 可选今日及以后日期，以后日期最大值为当前年度的12月31日
			if (e === 7) {
				setIfchange7(true)
				setIfchange4(false)
				setIfchange3(false)
				setIfchange12(false)
			} else if (e === 4) {
				setIfchange4(true)
				setIfchange7(false)
				setIfchange3(false)
				setIfchange12(false)
			} else if (e === 3) {
				setIfchange3(true)
				setIfchange7(false)
				setIfchange4(false)
				setIfchange12(false)
			} else if (e === 12) {
				setIfchange12(true)
				setIfchange7(false)
				setIfchange4(false)
				setIfchange3(false)
			} else {
				setIfchange12(false)
				setIfchange7(false)
				setIfchange4(false)
				setIfchange3(false)
			}
			setTimeout(() => {
				if (addAskType == 1) {
					setFieldsValue({
					  sDate: moment(sDateTime),
					})
				}
				chargeTimesAsk(getFieldValue('sTime'), e)
				getLeaveOutEndDate(e, getFieldValue('sTime'), getFieldValue('sDate') ? moment(getFieldValue('sDate')).format('YYYY-MM-DD') : undefined, getFieldValue('long'))
			}, 300)
		}
	}
	//修改起始日期
	const onChangeSDate = (e) => {
		api.getLeaveOutRecentSign({
			sDate: moment(e).format('YYYY-MM-DD')
		}).then(res => {
			setAttList(res)
		})
		getLeaveOutEndDate(getFieldValue('ask'), getFieldValue('sTime'), moment(e).format('YYYY-MM-DD'), getFieldValue('long'))
	}

	//育儿假子女出生日期
	const onChangeSDates = (e) => {
		api.getLeaveoutYuerscalc({
			date: moment(e).format('YYYY-MM-DD')
		}).then(res => {
			setAttList(res)
		})
	}

	//更改起始时间
	const getTimes = (e) => {
		let a = getFieldValue('ask')
		chargeTimesAsk(e, a)
		getLeaveOutEndDate(a, e, getFieldValue('sDate') ? moment(getFieldValue('sDate')).format('YYYY-MM-DD') : undefined, getFieldValue('long'))
	}
	//修改请假时长
	const onChangeLong = (e) => {
		setStepVal(e)
		if (e) {
			getLeaveOutEndDate(getFieldValue('ask'), getFieldValue('sTime'), getFieldValue('sDate') ? moment(getFieldValue('sDate')).format('YYYY-MM-DD') : undefined, e)
		}
	}
	//判断请假类型和起始时间
	const chargeTimesAsk = (sTime, ask) => {
		//当请假类型为公出1、事假2、病假3、产检假6、忘打卡7、丧假10、陪产假11、时，且起始时间为上午时，时长可以选择0.5,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15.
		//当请假类型为公出1、事假2、病假3、产检假6、忘打卡7、丧假10、陪产假11、时，且起始时间为下午时，时长默认为0.5天不可修改。
		//当请假类型为年假4时，起始时间默认为上午不可修改，时长可以选择1-x，x不超过该员工剩余年假 => 增加需求年假可选择0.5
		//当请假类型为婚假时，起始时间默认为上午不可修改，时长默认为10天不可修改
		//当请假类型为产假时，起始时间默认为上午不可修改，时长默认为128天不可修改
		//当请假类型为丧假时，起始时间默认为上午不可修改，时长默认为3天不可修改
		//当请假类型为哺乳假时，时长默认为365天不可修改
		setLongDis(false)
		setTimesDis(false)
		setFieldsValue({
			long: 0.5
		})
		setStepVal(0.5)
		setMin(0.5)
		setMax(15)
		if (sTime === '上午' || sTime && sTime.includes('上午')) {
			if (ask === 1 || ask === 3 || ask === 6 || ask === 7 || ask === 10 || ask === 11) {
				setMax(15)
			}
		}
		if (sTime === '下午' || sTime && sTime.includes('下午')) {
			if (ask === 1 || ask === 2 || ask === 3 || ask === 6 || ask === 7 || ask === 10 || ask === 11 || ask === 4) {
				setLongDis(true)
				setFieldsValue({
					long: 0.5
				})
			}
		}
		//事假
		if (ask === 2) {
			getLeaveOutAskRemainNum(ask)
		}
		//年假
		if (ask === 4) {
			// setTimesDis(true)
			// setFieldsValue({
			// 	sTime: '上午',
			// 	long: 1
			// })
			// setMin(1)
			// setStepVal(1)
			getLeaveOutAskRemainNum(ask)
		}
		if (ask === 5) {
			setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: 10
			})
			setLongDis(true)
			setStepVal(1)
		}
		if (ask === 9) {
			setTimesDis(true)
			setMax(200)
			setFieldsValue({
				sTime: '上午',
				long: 128
			})
			setMin(1)
			setStepVal(1)
			// setLongDis(true)
		}
		if (ask === 10) {
			setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: 3
			})
			setLongDis(true)
			setStepVal(1)
		}
		if (ask === 11) {
			setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: 15
			})
			setLongDis(true)
			setStepVal(1)
		}
		if (ask === 15) {
			setMax(365)
			// setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: 1
			})
			setMin(1)
			setStepVal(1)
		}
		if (ask === 12) {
			setMax(365)
			setFieldsValue({
				sTime: '上午',
				long: 365
			})
			setLongDis(true)
			setStepVal(1)
		}
		if (ask === 14) {
			setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: 1
			})
			setMin(1)
			setStepVal(1)
			getLeaveOutAskRemainNum(ask)
		}
		if (ask === 16) {
			setMax(42)
			setTimesDis(true)
			setFieldsValue({
				sTime: '上午',
				long: weekVal < 16 ? 15 : 42
			})
			setLongDis(true)
			setStepVal(1)
		}
	}
	//获取请假结束日期
	const getLeaveOutEndDate = (ask, sTime, sDate, long) => {
		if (ask !== undefined && sTime !== undefined && sDate !== undefined && long !== undefined) {
			let timer = setTimeout(() => {
				api.getLeaveOutEndDate({
					ask,
					// sTime: sTime === '上午' ? '上午 8:30' : '下午 13:30',
					sTime: ask == 7 ? sTime : sTime === '上午' ? '上午 8:30' : '下午 13:30',
					sDate,
					long
				}).then(res => {
					const { spanningYearDays } = res
					if (ask == 3 && +spanningYearDays > 0) {
						setModalFlag(true)
						setFieldsValue({
							long: long - +spanningYearDays
						})
					}
					setFieldsValue({
						eDate: res.endDate
					})
				})
				clearTimeout(timer)
			}, 500)
		} else {
			setFieldsValue({
				eDate: undefined
			})
		}
	}

	// 检测忘打卡
	const getCheckForget = (params) => {
		params.punchInTime = params.punchInTime && moment(params.punchInTime).format('HH:mm')
		const { ask, sTime, sDate, eDate, long, punchInAddress, punchInTime } = params
		api.getCheckForget({ ask, sTime, sDate, eDate, long, punchInAddress, punchInTime }).then(res => {
			if (res.forgetMore.msg && res.forgetMore.msg !== '') {
				setReminderModal(true)
				setSaveLoading(false)
				return
			} else {
				if (res.forgetMore) {
					setForgetModal(true)
					setForgetData(params)
					setSaveLoading(false)
				} else {
					onLeaveOutHandle(params)
				}
			}
		}).catch(() => {
			setSaveLoading(false)
		})
	}

	// 弹窗确定
	const onForgetOk = () => {
		setForgetModal(false)
		setSaveLoading(true)
		onLeaveOutHandle(forgetData)
	}

	// 提交表单申请
	const onLeaveOutHandle = (params) => {
		api.onLeaveOutHandle(params).then(res => {
			message.success('操作成功')
			setVisible(false)
			getLeaveOutList()
			setSaveLoading(false)
			setForgetData('')
		}).catch(() => setSaveLoading(false))
	}

	//提交表单
	const formSubmit = (e) => {
		e.preventDefault()
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.error('请不要频繁点击')
		}
		validateFields((err, vals) => {
			let params = Object.assign({}, vals)
			//如果是育儿假走得逻辑
			if (vals.ask == 13) {
				let uploadList2 = []
				for (let i = 0, k = fileList2.length; i < k; i++) {
					if (fileList2[i].status !== 'removed') {
						uploadList2.push({
							url: fileList2[i].url,
							name: fileList2[i].name
						})
					}
				}
				let uploadList3 = []
				for (let i = 0, k = fileList3.length; i < k; i++) {
					if (fileList3[i].status !== 'removed') {
						uploadList3.push({
							url: fileList3[i].url,
							name: fileList3[i].name
						})
					}
				}
				if (uploadList2.length > 0) {
					params.affix = JSON.stringify(uploadList2)
				} else {
					return message.error('请上传结婚证')
				}
				if (uploadList3.length > 0) {
					params.affix1 = JSON.stringify(uploadList3)
				} else {
					return message.error('请上传子女出生证明')
				}
				
				if (params.date1) params.date1 = moment(params.date1).format('YYYY-MM-DD')
			} else {
				let uploadList = []
				for (let i = 0, k = fileList.length; i < k; i++) {
					if (fileList[i].status !== 'removed') {
						uploadList.push({
							url: fileList[i].url,
							name: fileList[i].name
						})
					}
				}
				params.affix = uploadList
				params.sTime = params.ask == 7 ? params.sTime : params.sTime === '上午' ? '上午 8:30' : '下午 13:30'
				// params.sTime = params.sTime === '上午' ? '上午 8:30' : '下午 13:30'
				if (params.sDate) params.sDate = moment(params.sDate).format('YYYY-MM-DD')
			}
			if (params.ask == 16) {
				if (params.date1) params.date1 = moment(params.date1).format('YYYY-MM-DD')
				if (params.date2) params.date2 = moment(params.date2).format('YYYY-MM-DD')
				params.pregnancyCycle = weekVal
			} 
			if (params.remark === undefined) params.remark = ''
			if (vals.ask !== 13 && !params.long) {
				return message.warning('您的假期不足')
			}
			// if (!(/(^[1-9]\d*$)/.test(params.long)) && params.ask == 4) {
			// 	return message.warning('年假必须是整天数')
			// }
			//判断是否为驳回重提
			if (editId) params.id = editId
			if (!err) {
				setSaveLoading(true)
				//育儿假调的接口
				if (vals.ask == 13) {
					// console.log('vals.ask', vals, vals.ask, params)
					api.setLeaveoutYuerApply(params).then(res => {
						message.success('操作成功')
						setVisible(false)
						getLeaveOutList()
						setSaveLoading(false)
					}).catch(() => setSaveLoading(false))
					setSaveLoading(false)
				} else {
					// 检测忘打卡日期
					if (params.ask == 7) { 
						getCheckForget(params)
					} else {
						onLeaveOutHandle(params)
					}
				}
			}
		})
	}
	
	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx',
		allowSizeType: 4
	})

	//上传结婚证
	const uploadFiles2 = upLoadModule({
		fileList: fileList2,
		setFileList: setFileList2,
		draggerRef: draggerRef2,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx',
		allowSizeType: 4
	})

	//上传子女出生证明
	const uploadFiles3 = upLoadModule({
		fileList: fileList3,
		setFileList: setFileList3,
		draggerRef: draggerRef3,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx',
		allowSizeType: 4
	})

	const reminderTitle = () => {
		return <>系统提醒 {<Icon type="exclamation-circle" theme="twoTone" twoToneColor="#faad14" />}</>
	}

	return (
		<>
			<Form onSubmit={formSubmit} {...formItemLayout}>
				<FormItem label=" " colon={false} style={{ marginBottom: '10px' }}>
					<Row gutter={8}>
						<Col span={8}>
							姓名： {userInfo.staffName}
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							部门：{userInfo.companyGroupName}
						</Col>
					</Row>
				</FormItem>
				<FormItem label=" " colon={false} style={{ marginBottom: '10px' }}>
					<Row gutter={8}>
						<Col span={8}>
							员工编号：{userInfo.staffNo}
						</Col>
						<Col span={4}></Col>
						<Col span={8}>
							直属上级：{userInfo.leaderName}
						</Col>
					</Row>
				</FormItem>
				<FormItem label="请假类型">
					{getFieldDecorator('ask', {
						rules: [{ required: true, message: '请选择请假类型' }]
					})(
						<Select
							placeholder="请选择请假类型"
							style={{ width: '100%' }}
							onDropdownVisibleChange={bool => bool && getTypeList()}
							onChange={onChangeType}
							disabled={askDisabled}
						>
							{typeList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
						</Select>
					)}
				</FormItem>
				{
					askType == 16 ?
						<>
							<FormItem label="次末月经日期">
								{getFieldDecorator('date1', {
									rules: [{ required: true, message: '请选择次末月经日期' }]
								})(
									<DatePicker
										style={{ width: '100%' }}
										allowClear={false}
										type="crateTime"
										onChange={(e) => onChangeWeek(e, getFieldValue('date2'))}
									/>
								)}
							</FormItem>
							<FormItem label="妊娠终止日期">
								{getFieldDecorator('date2', {
									rules: [{ required: true, message: '请选择次末月经日期' }]
								})(
									<DatePicker
										style={{ width: '100%' }}
										allowClear={false}
										type="crateTime"
										disabled={!getFieldValue('date1')}
										disabledDate={endTimeDate}
										onChange={(e) => onChangeWeek(getFieldValue('date1'), e)}
									/>
								)}
							</FormItem>
							<FormItem label="怀孕周期">
								{getFieldDecorator('pregnancyCycle')(
									<span>{weekVal}周</span>
								)}
							</FormItem>
						</> : null
				}
				{
					askType == 13 ? <>
						<FormItem label="子女出生日期">
							{getFieldDecorator('date1', {
								rules: [{ required: true, message: '请选择子女出生日期' }]
							})(
								<DatePicker
									style={{ width: '100%' }}
									allowClear={false}
									type="crateTime"
									disabledDate={disabledDates}
									onChange={onChangeSDates}
								/>
							)}
						</FormItem>
						<div style={{ padding: '0 0 40px 40px' }}>
							<div style={{ fontWeight: 'bold', paddingBottom: '20px' }}>育儿假信息</div>
							<Table
								dataSource={attList}
								rowKey="id" 
								className="today-info"
								scroll={{ x: 1400 }}
								pagination={false}
							>
								<Column title="育儿假可用周期" dataIndex="show" width={260} />
								<Column title="可用时长" dataIndex="usable" />
							</Table>
						</div>
						<FormItem label="上传结婚证">
							<span style={{ position: 'absolute', left: '-100px', top: '0px', color: 'red' }}>*</span>
							<div className="dragger-box" ref={draggerRef2}>
								<Dragger
									{...uploadFiles2}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
									<p className="ant-upload-hint">
										支持扩展名：.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx
									</p>
								</Dragger>
							</div>
						</FormItem>
						<FormItem label="上传子女出生证明">
							<span style={{ position: 'absolute', left: '-140px', top: '0px', color: 'red' }}>*</span>
							<div className="dragger-box" ref={draggerRef3}>
								<Dragger
									{...uploadFiles3}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
									<p className="ant-upload-hint">
										支持扩展名：.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx
									</p>
								</Dragger>
							</div>
						</FormItem>
					</> : <>
						<FormItem label={askType == 12 ? '子女出生日期' : '起始日期'}>
							{getFieldDecorator('sDate', {
								rules: [{ required: true, message: '请选择起始日期' }]
							})(
								<DatePicker
									style={{ width: '100%' }}
									allowClear={false}
									type="crateTime"
									disabled={addAskType == 1 ? true : false}
									disabledDate={disabledDate}
									onChange={onChangeSDate}
								/>
							)}
						</FormItem>
						{
							getFieldValue('ask') == 7 ?
								<FormItem label="起始时间">
									{getFieldDecorator('sTime', {
										rules: [{ required: true, message: '请选择起始时间' }]
									})(
										<Select
											placeholder="请选择起始时间"
											style={{ width: '100%' }}
											onChange={getTimes}
											disabled={timesDis}
										>
											<Option value="上午签到 8:30">上午签到</Option>
											<Option value="下午签退 17:30">下午签退</Option>
											<Option value="上午签退 12:00">上午签退</Option>
											<Option value="下午签到 13:30">下午签到</Option>
										</Select>
									)}
								</FormItem> :
								<FormItem label="起始时间">
									{getFieldDecorator('sTime', {
										rules: [{ required: true, message: '请选择起始时间' }]
									})(
										<Select
											placeholder="请选择起始时间"
											style={{ width: '100%' }}
											onChange={getTimes}
											disabled={timesDis}
										>
											<Option value="上午">上午</Option>
											<Option value="下午">下午</Option>
										</Select>
									)}
								</FormItem>
						}
						{
							getFieldValue('ask') !== 7 ?
								<>
									<FormItem label="请假时长">
										{getFieldDecorator('long', {
											initialValue: 0.5, rules: [{ required: true, message: '请选择请假时长' }, {
												pattern: /^[1-9]\d*$|0\.[5]$/,
												message: '请输入正确的请假时长',
											}]
										})(<InputNumber
											min={min}
											max={max}
											step={stepVal === 0.5 ? 0.5 : 1}
											disabled={longDis}
											onChange={onChangeLong}
										/>)}
										{min === 0 && <div className="ant-form-explain" style={{ color: '#f5222d' }}>{askTip}</div>}
									</FormItem>
									<FormItem label={<Tooltip title="公出、事假、年假、产检假、忘打卡、育儿假以工作日计算；病假、婚假、产假、陪产假、哺乳假、孕满28周、小产假、丧假以自然日计算。" placement="bottomLeft">结束日期①</Tooltip>}>
										{getFieldDecorator('eDate', {
											rules: [{ required: false, message: '' }]
										})(
											<Input autoComplete="off" placeholder="结束日期" disabled style={{ width: '110px', background: '#fff', cursor: 'default', color: '#333' }} />
										)}
									</FormItem>
								</> :
								<>
									<Row gutter={24}>
										<Col span={9} offset={1}>
											<FormItem label="忘打卡时间" labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
												{getFieldDecorator('punchInTime', {
													rules: [{ required: true, message: '请选择忘打卡时间' }]
												})(<TimePicker format="HH:mm" getPopupContainer={trigger => trigger.parentNode} />)}
											</FormItem>
										</Col>
										<Col span={8} offset={2}>
											<FormItem label="请假时长" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
												{getFieldDecorator('long', {
													initialValue: 0.5, rules: [{ required: true, message: '请选择请假时长' }, {
														pattern: /^[1-9]\d*$|0\.[5]$/,
														message: '请输入正确的请假时长',
													}]
												})(<InputNumber
													min={min}
													max={max}
													step={stepVal === 0.5 ? 0.5 : 1}
													disabled={true}
													onChange={onChangeLong}
												/>)}
												{min === 0 && <div className="ant-form-explain" style={{ color: '#f5222d' }}>{askTip}</div>}
											</FormItem>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={9} offset={1}>
											<FormItem label="忘打卡地点" labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
												{getFieldDecorator('punchInAddress', {
													initialValue: 1, rules: [{ required: true, message: '请选择忘打卡地点' }]
												})(<Radio.Group>
													<Radio value={1}>在司</Radio>
													<Radio value={2}>居家</Radio>
												</Radio.Group>)}
												{min === 0 && <div className="ant-form-explain" style={{ color: '#f5222d' }}>{askTip}</div>}
											</FormItem>
										</Col>
										<Col span={8} offset={2}>
											<FormItem
												labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
												label={<Tooltip title="公出、事假、年假、产检假、忘打卡、育儿假以工作日计算；病假、婚假、产假、陪产假、哺乳假、孕满28周、小产假、丧假以自然日计算。"
													placement="bottomLeft"
												>结束日期①</Tooltip>}>
												{getFieldDecorator('eDate', {
													rules: [{ required: false, message: '' }]
												})(
													<Input autoComplete="off" placeholder="结束日期" disabled style={{ width: '110px', background: '#fff', cursor: 'default', color: '#333' }} />
												)}
											</FormItem>
										</Col>
									</Row>
								</>
						}
						<div style={{ padding: '0 0 40px 40px' }}>
							<div style={{ fontWeight: 'bold', paddingBottom: '20px' }}>当天考勤信息</div>
							<Table
								dataSource={attList}
								rowKey="id"
								className="today-info"
								scroll={{ x: 1400 }}
								pagination={false}
							>
								<Column title="日期" dataIndex="date" width={120} />
								<Column title="姓名" dataIndex="userName" />
								<Column title="状态" dataIndex="statusName" />
								<Column title="签到" dataIndex="signIn" />
								<Column title="签退" dataIndex="signOut" />
								<Column title="迟到" dataIndex="late" />
								<Column title="早退" dataIndex="leave" />
								<Column title="未打卡" dataIndex="notCheck" />
								<Column title="打卡无效" dataIndex="invalid" />
								<Column title="请假外出" dataIndex="ask_text" />
								<Column title="时长" dataIndex="long" />
								<Column title="直属上级" dataIndex="lead" />
							</Table>
						</div>
						<FormItem label="上传附件">
							{getFieldDecorator('dragger', { rules: [{ required: false, message: '请上传附件' }] })(
								<div className="dragger-box" ref={draggerRef}>
									<Dragger
										{...uploadFiles}
									>
										<p className="ant-upload-drag-icon">
											<Icon type="inbox" />
										</p>
										<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
										<p className="ant-upload-hint">
											支持扩展名：.rar, .zip, .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .pptx, .PDF, .csv, .xlsx
										</p>
									</Dragger>
								</div>
							)}
						</FormItem>
					</>
				}
				<FormItem label="备注">
					{getFieldDecorator('remark')(
						<Input.TextArea className="content-area" placeholder="请输入备注" rows={4} style={{ width: "100%" }} />
					)}
				</FormItem>
				<BtnGroup onCancel={() => setVisible(false)} loading={saveLoading} />
			</Form>
			<Modal
				title="提示"
				visible={modalFlag}
				onOk={() => setModalFlag(false)}
				closable={false}
				okText='确认'
				footer={[
					<Button key="submit" type="primary" onClick={() => setModalFlag(false)}>确认</Button>
				]}
			>
				病假申请时日期不允许跨自然年，系统已自动调整本次病假申请至本自然年内，下一年度病假请于1月1日之后申请。
			</Modal>
			<Modal
				title="提示"
				visible={forgetModal}
				onOk={onForgetOk}
				onCancel={() => setForgetModal(false)}
				okText='确认'
			>
				该日期有多个忘打卡可申请，请核对您本次申请的信息是否正确，点击确认后提交
			</Modal>
			<Modal
				title={reminderTitle()}
				visible={reminderModal}
				onCancel={() => setReminderModal(false)}
				okText='关闭'
				footer={[
					<Button type="primary" onClick={() => setReminderModal(false)}>关闭</Button>
				]}
			>
				<p style={{ textIndent: '2em' }}>系统中已存在比您填写的 ”忘打卡时间” 更早（或更晚）的签到（或签退）时间，请检查后重新提交。</p>
			</Modal>
		</>
	)
}
export default Form.create()(Addnewask)