/**
 * 模块名称: 权限管理
 * @author lids@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RoleManage from './RoleManage'
import JobManage from './JobManage'
import SpecialManage from './SpecialManage'
import StaffManage from './StaffManage'
import AddRole from './module/AddRole'
import './assets/style.scss'

const CorpIndex = ({ match, history }) => {

  return (
    <Switch>
      {/* <Route exact path={match.path} component={authManagement} /> */}
      <Route path={match.path + '/roleManage'} exact component={RoleManage} />
      <Route path={match.path + '/jobManage'} exact component={JobManage} />
      <Route path={match.path + '/staffManage'} exact component={StaffManage} />
      <Route path={match.path + '/specialManage'} exact component={SpecialManage} />
      <Route path={match.path + '/roleManage/addRole'} exact component={AddRole} />
      <Route path={match.path + '/roleManage/editRole'} exact component={AddRole} />
      <Route component={RoleManage} />
    </Switch>
  )
}

export default CorpIndex