/**
 * 模块名称: 特殊权限
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from "react"
import api from "../../api"
import {
  Table,
  Form,
  Alert,
  Icon,
  Drawer,
  Spin,
  Input,
  Button,
  Select,
  Modal,
  message,
  Row,
  Col
} from "antd"
import CorpFilter from "../../components/CorpFilter"
import Auth from "../../components/AuthMiddleware"
import { parseSearch } from "../../utils"

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let corpId = ""
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  corpId: corpId,
}

const SpecialManage = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, } = props.form

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])

  const [loading, setLoading] = useState(false)

  const [roleType, setRoleType] = useState("全部")

  const [departmentList, setDepartmentList] = useState([])

  const [authList, setAuthList] = useState([]) //按钮权限
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
    }
    corpId = ""
    SpecialPermissions()
    getPageAuth()
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then((list) => { setAuthList(list) })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  //点击选择所属公司
  const onChangeCorp = data => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({ departmentId: undefined })
    currentPage = 1
    if (data.length) {
      let arr = []
      data.forEach(item => {
        arr.push(item.id)
      })
      corpId = arr.join(",")
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        corpId: corpId,
      }
    } else {
      corpId = ""
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
      }
    }
    history.replace(match.path)
    SpecialPermissions()
  }

  //点击重置
  const resetForm = () => {
    currentPage = 1
    corpId ? (searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      corpId: corpId,
    }) : (searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
    })
    history.replace(match.path)
    setRoleType("全部")
    resetFields()
    SpecialPermissions()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + "?page=" + currentPage + "&limit=" + pageSize)
    SpecialPermissions()
  }

  //授权
  const onAuthorization = record => {
    // console.log(record)
    api.setRepeatLoginHandle({ staffId: record.id, status: record.status }).then(res => {
      message.success('修改成功')
      SpecialPermissions()
    }).catch(err => console.log(err))
  }

  //获取列表
  const SpecialPermissions = () => {
    setLoading(true)
    if (corpId.length) searchParamsTmp.corpId = corpId
    api.getRepeatLoginList(searchParamsTmp).then((res) => {
      // console.log(res)
      setCount(res.count)
      setList(res.list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  //获取部门列表
  const getDepartmentList = () => {
    api.getSelectDepartmentList(
      corpId.length ? { limit: global.paramsLimit, companyId: corpId } : { limit: global.paramsLimit }
    ).then((res) => {
      setDepartmentList(res.list)
    })
  }

  //数据转换
  const transData = vals => {
    searchParamsTmp = { ...searchParamsTmp, ...vals }
    for (let item in searchParamsTmp) {
      if (searchParamsTmp[item] === undefined || item === "roleType") {
        delete searchParamsTmp[item]
      }
    }
  }
  //点击筛选
  const searchSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if (vals.roleType && !vals.roleId)
        return message.warning("请选择角色后筛选")
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        SpecialPermissions()
      }
    })
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-role-wrap">
        <h4 className="title">其它选项：</h4>
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <Row gutter={24}>
            <Col span={7}>
              <FormItem label="员工编号">
                {getFieldDecorator("no")(
                  <Input placeholder="请输入员工编号" style={{ width: 200 }} />
                )}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="公司用名">
                {getFieldDecorator("name")(
                  <Input placeholder="请输入公司用名" style={{ width: 200 }} />
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="部门">
                {getFieldDecorator("departmentId")(
                  <Select
                    placeholder="全部"
                    onDropdownVisibleChange={(bool) => bool && getDepartmentList()}
                    optionFilterProp="children"
                    showSearch
                    dropdownMatchSelectWidth={false}
                    style={{ width: 200 }}
                  >
                    {departmentList.map((v, index) => {
                      return !corpId.length ? (
                        <Option
                          title={v.name + "（" + v.companyName + "）"}
                          key={v.id}
                        >
                          {v.name}（{v.companyName}）
                        </Option>
                      ) : (
                        <Option title={v.name} key={v.id}>
                          {v.name}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem className="btns" label=" " colon={false}>
                <Button onClick={resetForm}>重置</Button>
                <Button type="primary" htmlType="submit">筛选</Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap"></div>
      {/* <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon /> */}
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="staff-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" />
        <Column title="员工编号" dataIndex="staffNo" />
        <Column title="公司用名" dataIndex="staffName" />
        <Column title="所属部门" dataIndex="departmentName" />
        <Column title="所属公司" dataIndex="companyName" />
        <Column title="职位" dataIndex="jobPositionCn" />
        <Column title="直接主管" dataIndex="leaderName" />
        <Column title="主副账号" dataIndex="isMainAccountCn" />
        <Column title="在职离职" dataIndex="isOnJobCn" />
        <Column title="操作" key="set" width={80} fixed="right" render={(text, record) => (
          <>
            <Auth auths={authList} code="operate">
              <span
                style={{ color: "#1890ff", cursor: 'pointer' }}
                onClick={() => onAuthorization(record)}
              >
                {record.status == 1 ? '取消授权' : '授权'}
              </span>
            </Auth>
          </>
        )}
        />
      </Table>
    </>
  )
}

export default Form.create()(SpecialManage)
