/**
 * 模块名称: 添加角色/编辑角色
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setMenuList } from '@/global/reducer/menuList'
import api from '@/api'
import {
	Table,
	Input,
	Layout,
	Checkbox,
	Button,
	message,
	Form,
	Tooltip,
	Drawer
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { delArrInArr } from '@/utils'

const { Footer, Content } = Layout
const FormItem = Form.Item
let disabled = true//防止频繁点击

var authData = [], selMenuIds = [], getHasSelKeys = []

const AddRole = (props) => {
	//const { getFieldDecorator, validateFields, setFieldsValue } = props.form
	const { history, location } = props
	const [loading, setLoading] = useState(true)
	const [datalist, setDatalist] = useState([])
	const [visible, setVisible] = useState(false)
	const [corpList, setCorpList] = useState([])

	const [corpData, setCorpData] = useState([])
	const [drawerObj, setDrawerObj] = useState({})
	const [initialCorpIds, setInitialCorpIds] = useState([])
	// const [indeterminate, setIndeterminate] = useState(true)
	const [checkAll, setCheckAll] = useState(false)

	const [roleName, setRoleName] = useState('')
	const [roleDesc, setRoleDesc] = useState('')
	const [tName, setTName] = useState('添加角色')
	const [selectedRowKeysData, setSelectedRowKeysData] = useState([])
	const [drawerName, setDrawerName] = useState('')

	const dispatch = useDispatch()

	useEffect(() => {
		//初始化这些参数 防止前进后退、页面造成数据缓存bug
		disabled = true
		authData = []
		selMenuIds = []
		getHasSelKeys = []
		setTName('添加角色')
	}, [])

	useEffect(() => {
		initData()
	}, [])

	const initData = () => {
		if (location.state) {
			//根据角色id编辑
			setTName('编辑角色')
			setRoleName(location.state.name)
			setRoleDesc(location.state.desc)
			getAuthByRoleId(location.state.id)
		} else {
			//添加角色
			setTName('添加角色')
			//roleName = ''
			setRoleName('')
			setRoleDesc('')
			getButtonAuthDefaultSettingTree()
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}
	//根据角色id获取菜单权限信息
	const getAuthByRoleId = (id) => {
		let params = {
			id: id
		}
		api.getAuthByRoleId(params).then(res => {
			let list = res
			transDataDefault(list)
		})
	}

	//获取所有角色初始化信息
	const getButtonAuthDefaultSettingTree = () => {
		api.getButtonAuthDefaultSettingTree().then(res => {
			let list = res.list
			transDataDefault(list)
		})
	}
	//数据转换default
	const transDataDefault = list => {
		for (let i = 0, len = list.length; i < len; i++) {
			list[i].key = list[i].id
			list[i].title = <span style={{ fontWeight: 'bold' }}>{list[i].title}</span>
			let children = list[i].children
			if (children.length) {
				for (let j = 0, lenC = children.length; j < lenC; j++) {
					children[j].key = children[j].id
					//children[j].title = children[j].title

					let authSelf = children[j].auth.authSelf
						, authSelfJob = children[j].auth.authSelfJob
						, authSelfDepartment = children[j].auth.authSelfDepartment
						, authAllDepartment = children[j].auth.authAllDepartment

					transferToBtns(authSelf, children[j], 'role', 'authSelf')
					transferToBtns(authSelfJob, children[j], 'job', 'authSelfJob')
					transferToBtns(authSelfDepartment, children[j], 'department', 'authSelfDepartment')
					transferToBtns(authAllDepartment, children[j], 'all', 'authAllDepartment')
				}
			}
		}
		setLoading(false)
		setDatalist(list)
		authData = getAllChecks(list)
	}
	//按钮转换
	const transferToBtns = (btns, children, type, belong) => {
		children[type] = []
		//如果三级菜单为空 直接给返回null 则不再显示展开加号按钮
		if (children.children && !children.children.length) children.children = null
		if (btns && btns.length) {
			btns.forEach(item => {
				item['belong'] = belong
				children[type].push(
					item.buttonType === 'checkbox' ?
						<Checkbox
							key={item.id}
							style={{ width: '50%', marginLeft: 0 }}
							title={item.buttonText}
							onChange={(e) => checkChange(e)}
							datamenuid={children.auth.menuId || children.id}
							datapid={children.pid}
							belong={item.belong}
							disabled={!item.enable}
							defaultChecked={item.selected}
							buttoncode={item.buttonCode}
							buttontype={item.buttonType}
						>
							{item.buttonText}
						</Checkbox> :
						<Button
							key={item.id}
							style={{ width: '60%', marginLeft: 0, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
							title={item.buttonText}
							onClick={() => openDrawer(children.auth.menuId, item)}
							datamenuid={children.auth.menuId || children.id}
							datapid={children.pid}
							belong={item.belong}
							disabled={!item.enable}
							buttoncode={item.buttonCode}
							buttontype={item.buttonType}
						>
							{item.buttonText}
						</Button>)
			})
		}
	}
	//获取所有的按钮选择的情况
	const getAllChecks = dataall => {
		let auth = [], arr = []
		for (let i = 0, len = dataall.length; i < len; i++) {
			auth.push({
				menuId: dataall[i].auth.menuId || dataall[i].id,
				isShow: dataall[i].auth.isShow || 0,
				authSelf: dataall[i].auth.authSelf,
				authSelfJob: dataall[i].auth.authSelfJob,
				authSelfDepartment: dataall[i].auth.authSelfDepartment,
				authAllDepartment: dataall[i].auth.authAllDepartment
			})
			if (dataall[i].auth.isShow === 1) arr.push(dataall[i].id)
			let children = dataall[i].children
			for (let j = 0, lenJ = children.length; j < lenJ; j++) {
				auth.push({
					menuId: children[j].auth.menuId || children[j].id,
					isShow: children[j].auth.isShow || 0,
					authSelf: children[j].auth.authSelf,
					authSelfJob: children[j].auth.authSelfJob,
					authSelfDepartment: children[j].auth.authSelfDepartment,
					authAllDepartment: children[j].auth.authAllDepartment
				})
				if (children[j].auth.isShow === 1) arr.push(children[j].id)
			}
		}
		setSelectedRowKeysData([...new Set(arr)])
		getHasSelKeys = [...new Set(arr)]
		return [...new Set(auth)]
	}
	//选择按钮check
	const checkChange = (e) => {
		if (e.target.checked) {
			let newArr = []
			selMenuIds.push(e.target.datapid)
			selMenuIds.push(e.target.datamenuid)
			if (location.state) {
				newArr = [...new Set([...selMenuIds, ...selectedRowKeysData, ...getHasSelKeys])]
				setSelectedRowKeysData(newArr)
			} else {
				newArr = [...new Set([...selMenuIds, ...selectedRowKeysData])]
				setSelectedRowKeysData(newArr)
			}
			for (let i = 0, len = authData.length; i < len; i++) {
				authData[i].isShow = 0
				for (let j = 0, lenJ = newArr.length; j < lenJ; j++) {
					if (authData[i].menuId === newArr[j]) {
						authData[i].isShow = 1
					}
				}
			}
		}
		let isC = false
		for (let i = 0, len = authData.length; i < len; i++) {
			if (authData[i].menuId === e.target.datamenuid) {
				for (let j = 0; j < authData[i][e.target.belong].length; j++) {
					if (authData[i][e.target.belong][j].buttonCode === e.target.buttoncode) {
						authData[i][e.target.belong][j].selected = e.target.checked
					}
				}
				if (e.target.buttoncode === 'browse' && !e.target.checked) {
					for (let j = 0, lenJ = authData[i][e.target.belong].length; j < lenJ; j++) {
						authData[i][e.target.belong][j].selected = false
					}
					return
				}
				for (let j = 0, lenJ = authData[i][e.target.belong].length; j < lenJ; j++) {
					if (authData[i][e.target.belong][j].selected) isC = true
				}
				for (let j = 0, lenJ = authData[i][e.target.belong].length; j < lenJ; j++) {
					if (authData[i][e.target.belong][j].buttonCode === 'browse' && isC) {
						authData[i][e.target.belong][j].selected = true
					}
				}
			}
		}
	}

	const columns = [
		{
			title: '菜单',
			dataIndex: 'title',
			key: 'title',
			width: '20%'
		},
		{
			title: '本人',
			dataIndex: 'role',
			key: 'role',
			width: '20%'
		},
		{
			title: '本职',
			dataIndex: 'job',
			key: 'job',
			width: '20%'
		},
		{
			title: '本部',
			dataIndex: 'department',
			key: 'department',
			width: '20%'
		},
		{
			title: '全部',
			dataIndex: 'all',
			key: 'all',
			width: '20%'
		}
	]

	const rowSelection = {
		//onChange 先于 onSelect执行
		onChange: (selectedRowKeys, selectedRows) => {
			//console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)

			//修改左侧菜单的时候将从接口获得的已有idlist清空
			getHasSelKeys = []
		},
		onSelect: (record, selected, selectedRows) => {
			// console.log(record, selected, selectedRows)
			let arr = [], pArrId = []
			//先将所有勾选的菜单的id存到arr
			if (selectedRows.length) {
				selectedRows.forEach(item => {
					arr.push(item.id)
					if (item.pid !== 0) {
						pArrId.push(item.pid)
					}
				})
			}
			if (record.pid == 0) {//勾选一级菜单 二级菜单全部选中
				if (selected) {//如果为选中则全选
					arr.push(record.id)
					if (record.children && record.children.length) {
						for (let i = 0, len = record.children.length; i < len; i++) {
							arr.push(record.children[i].id)
						}
					}
				} else {//如果为非选中则将该一级菜单下的二级菜单全部取消勾选，从arr中删除
					let sonArr = []
					if (record.children && record.children.length) {
						for (let i = 0, len = record.children.length; i < len; i++) {
							sonArr.push(record.children[i].id)
						}
					}
					arr = delArrInArr(sonArr, arr)
					if (pArrId.length) {
						for (let i = 0, len = pArrId.length; i < len; i++) {
							if (pArrId[i] == record.id) {
								pArrId.splice(i, 1)
								i--
							}
						}
					}
				}
			} else {//如果选中2级菜单，则查找该菜单的父级菜单的children中的id是否还有包含在arr中的，如果没有，则取消父级菜单的选中状态
				let currMenuIdsList = []
				datalist.forEach(item => {
					if (item.id == record.pid) {
						item.children && item.children.forEach(itemm => {
							currMenuIdsList.push(itemm.id)
						})
					}
				})
				for (let i = 0, len = arr.length; i < len; i++) {
					if (!currMenuIdsList.includes(arr[i])) {
						arr = delArrInArr([record.pid], arr)
					}
				}
			}
			let newArr = [...new Set(arr.concat(pArrId))]
			selMenuIds = [...newArr]
			setSelectedRowKeysData(newArr)

			for (let i = 0, len = authData.length; i < len; i++) {
				authData[i].isShow = 0
				for (let j = 0, lenJ = newArr.length; j < lenJ; j++) {
					if (authData[i].menuId === newArr[j]) {
						authData[i].isShow = 1
					}
				}
			}
		},
		//全选
		onSelectAll: (selected, selectedRows, changeRows) => {
			//console.log(selected, selectedRows, changeRows)
			let arr = []
			selectedRows.forEach(item => {
				arr.push(item.id)
			})
			if (selected) {
				setSelectedRowKeysData(arr)
				selMenuIds = arr
			} else {
				setSelectedRowKeysData([])
				selMenuIds = []
			}
			for (let i = 0, len = authData.length; i < len; i++) {
				authData[i].isShow = selected ? 1 : 0
			}
		},
		selectedRowKeys: selectedRowKeysData
	}
	//角色名字
	const getName = (val) => {
		setRoleName(val.target.value)
	}
	//角色说明
	const getDesc = (val) => {
		setRoleDesc(val.target.value)
	}
	//添加角色//编辑角色
	const roleAdd = () => {
		let dataTmp = [].concat(authData)
		for (let i = 0, len = dataTmp.length; i < len; i++) {
			let authAllDepartment = dataTmp[i].authAllDepartment
			if (authAllDepartment && authAllDepartment.length) {
				for (let j = 0, lenJ = authAllDepartment.length; j < lenJ; j++) {
					if (authAllDepartment[j].belong) delete authAllDepartment[j].belong
				}
			}
			let authSelf = dataTmp[i].authSelf
			if (authSelf && authSelf.length) {
				for (let j = 0, lenJ = authSelf.length; j < lenJ; j++) {
					if (authSelf[j].belong) delete authSelf[j].belong
				}
			}
			let authSelfDepartment = dataTmp[i].authSelfDepartment
			if (authSelfDepartment && authSelfDepartment.length) {
				for (let j = 0, lenJ = authSelfDepartment.length; j < lenJ; j++) {
					if (authSelfDepartment[j].belong) delete authSelfDepartment[j].belong
				}
			}
			let authSelfJob = dataTmp[i].authSelfJob
			if (authSelfJob && authSelfJob.length) {
				for (let j = 0, lenJ = authSelfJob.length; j < lenJ; j++) {
					if (authSelfJob[j].belong) delete authSelfJob[j].belong
				}
			}
		}
		if (!roleName.length) {
			return message.warning('请填写角色名称')		
		}
		if (!roleDesc.length) {
			return message.warning('请填写角色描述')			
		}
		if (!authData.length) {
			return message.warning('请选择相应的角色权限')
		}
		if (disabled) {
			disabled = false
			let timer = setTimeout(() => {
				disabled = true
				clearTimeout(timer)
			}, 1000)
		} else {
			return message.error('请不要频繁点击')	
		}
		let params = {}
		//编辑角色
		if (location.state) {
			params = {
				id: location.state.id,
				name: roleName,
				desc: roleDesc,
				auth: JSON.stringify(dataTmp)
			}
			// console.log('dataTmp =>', dataTmp)
			api.onRoleEdit(params).then(() => {
				//成功
				// history.push('/authManagement/roleManage')
				history.goBack()
				dispGetMenu()
			})
			//添加角色
		} else {
			params = {
				name: roleName,
				desc: roleDesc,
				type: 'custom',
				auth: JSON.stringify(dataTmp)
			}
			api.onRoleAdd(params).then(() => {
				//成功
				// history.push('/authManagement/roleManage')
				history.goBack()
				dispGetMenu()
			})
		}
	}
	//修改完权限之后同步修改左侧菜单
	const dispGetMenu = () => {
		api.getMenu().then(menu => {
			dispatch(setMenuList(menu))
		})
	}
	//取消新建角色
	const onCancel = () => {
		// history.push('/authManagement/roleManage')
		history.goBack()
	}
	//获取公司
	const getRoleModuleCompanyList = menuId => {
		api.getRoleModuleCompanyList({ limit: global.paramsLimit, menuId }).then(res => {
			let arr = [].concat(res)
			arr.forEach((item, index, self) => {
				self[index].disabled = false
				self[index].label = item.name
				self[index].value = item.id
				if (!item.enable) self[index].disabled = !item.enable
			})
			setCorpList(arr)
			let DATA = []
			for (let i = 0, len = arr.length; i < len; i++) {
				DATA.push({
					id: arr[i].id,
					selected: false
				})
			}
			setCorpData(DATA)
		})
	}
	//选择公司
	const formSubmit = (e) => {
		e.preventDefault()
		for (let i = 0, len = corpData.length; i < len; i++) {
			for (let j = 0, lenJ = initialCorpIds.length; j < lenJ; j++) {
				if (initialCorpIds[j] === corpData[i].id) {
					corpData[i].selected = true
				}
			}
		}

		for (let i = 0, len = authData.length; i < len; i++) {
			if (authData[i].menuId === drawerObj.menuId) {
				for (let j = 0, lenJ = authData[i][drawerObj.belong].length; j < lenJ; j++) {
					if (authData[i][drawerObj.belong][j].buttonCode === drawerObj.buttonCode) {
						authData[i][drawerObj.belong][j].content = corpData
					}
				}
			}
		}
		authData.forEach(item => {
			if (item.menuId === drawerObj.menuId) {
				// item.authAllDepartment.forEach((v, i, a) => {
				item[drawerObj.belong].forEach((v, i, a) => {
					if (v.buttonType === 'company-layer') {
						a[i].isSelectAll = drawerObj.isSelectAll
					}
				})
			}
		})
		setVisible(false)
		setDrawerName('')
		setInitialCorpIds([])//初始化
	}
	//取消提交设置公司数据
	const resetForm = () => {
		setVisible(false)
		setDrawerName('')
		setDrawerObj({})
		for (let i = 0, len = corpData.length; i < len; i++) {
			corpData[i].selected = false
		}
	}
	//打开设置公司数据
	const openDrawer = (menuId, item) => {
		setDrawerObj({
			menuId,
			belong: item.belong,
			buttonCode: item.buttonCode,
			content: item.content,
			isSelectAll: item.isSelectAll
		})
		//如果是选择公司范围设置数据
		if (item.buttonType === 'company-layer') setSelectedCorp(item.content, item.isSelectAll)
		getRoleModuleCompanyList(menuId)
		if (item.buttonText == '提单范围') setDrawerName('请设置提单范围')
		if (item.buttonText == '全部公司') setDrawerName('请设置公司数据')
		setVisible(true)
	}
	//设置选中的公司,没有则为空
	const setSelectedCorp = (content, isSelectAll) => {
		let arr = []
		if (!isSelectAll) {
			content && content.forEach(item => {
				if (item.selected) arr.push(item.id)
			})
			setInitialCorpIds(arr)
		} else {
			setInitialCorpIds([])
		}
		setCheckAll(isSelectAll)
	}
	//关闭打开公司数据
	const closeDrawer = () => {
		setVisible(false)
		setDrawerName('')
		setDrawerObj({})
		for (let i = 0, len = corpData.length; i < len; i++) {
			corpData[i].selected = false
		}
	}
	//选择公司点击
	const onChangeCheckCorp = data => {
		setInitialCorpIds(data)
		drawerObj.isSelectAll = false
		setDrawerObj(drawerObj)
		setCheckAll(false)
		//setIndeterminate(!!data.length && data.length < corpList.length)
		//setCheckAll(data.length === corpList.length)
	}
	//全选
	const onChangeCheckCorpAll = (e) => {
		/*let arr = []
		for(let i =0; i<corpList.length; i++){
			if(!corpList[i].disabled){
				arr.push(corpList[i].id)
			}			
		}
		setInitialCorpIds(e.target.checked ? arr : [])*/
		//setIndeterminate(false)
		drawerObj.isSelectAll = e.target.checked
		setDrawerObj(drawerObj)
		setInitialCorpIds([])
		setCheckAll(e.target.checked)
	}
	return (
		<>
			<div className="drawer-container">
				<Layout>
					<Content className="drawer-header">
						<div className="header-title">{tName}</div>
						<div className="header-info">
							<div className="info-item">
								<div className="info-label">角色名称</div>
								<Input onChange={getName} maxLength={30} value={roleName} className="info-input" type="text" placeholder="请输入角色名称" />
							</div>
							<div className="info-item">
								<div className="info-label">角色说明</div>
								<Input onChange={getDesc} value={roleDesc} className="info-input" type="text" placeholder="请输入角色说明" />
							</div>
						</div>
						<Table
							dataSource={datalist}
							rowKey="id"
							loading={loading}
							className="role-table"
							columns={columns}
							bordered
							rowSelection={rowSelection}
							pagination={false}
						/>
					</Content>
					<Footer>
						<BtnGroup cancelName="取消" confirmName="提交" onConfirm={roleAdd} onCancel={onCancel} />
					</Footer>
				</Layout>
				<Drawer
					title={drawerName}
					width={800}
					onClose={closeDrawer}
					visible={visible}
					destroyOnClose={true}
				>
					<Form className="addSon-role-form" onSubmit={formSubmit} {...formItemLayout}>
						<FormItem label={<Tooltip title="该选项将影响筛选项以及列表数据仅能查看已选择公司的数据" placement="bottomLeft">公司数据</Tooltip>}>
							<Checkbox
								//indeterminate={indeterminate}
								onChange={onChangeCheckCorpAll}
								checked={checkAll}
							>全部</Checkbox>
							<Checkbox.Group
								options={corpList}
								//style={{ width: '100%', display: 'inline', marginLeft: '8px' }} 
								onChange={onChangeCheckCorp}
								value={initialCorpIds}
							>
							</Checkbox.Group>
						</FormItem>
						<FormItem className="btns addSon-role-btns" label=" " colon={false}>
							<Button onClick={resetForm}>取消</Button>
							<Button className="submit" type="primary" htmlType="submit">确定</Button>
						</FormItem>
					</Form>
				</Drawer>
			</div>
		</>
	)
}
export default Form.create()(AddRole)