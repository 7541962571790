/**
 * 模块名称: 考勤管理 -> 修改时长
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  message,
  DatePicker,
  Radio,
  Spin,
  InputNumber
} from 'antd'
import moment from 'moment'
import BtnGroup from 'components/BtnGroup'

const FormItem = Form.Item
const EditLong = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const { setTimeVisible, timeData, getLeaveOutList } = props
  const { id, askType, sTime, sDate, long, editLong, userId } = timeData
  const [timeloding, setTimeLoding] = useState(true)
  const [saveLoding, setSaveLoading] = useState(false)
  const [dateVal, setDateVal] = useState(undefined)

  useEffect(() => {
    if (editLong === 0) { // 未修改时长
      if ([128, 143].includes(long)) {
        setFieldsValue({
          sDate: sDate ? moment(sDate) : null,
          long: long === 128 ? '1' : '2'
        })
        getLeaveOutEndDate(askType, sTime, moment(sDate).format('YYYY-MM-DD'), long)
      } else { // 初始值是128的情况
        setFieldsValue({
          sDate: sDate ? moment(sDate) : null,
          long: '3'
        })
        onchangeDate(long)
      }
    } else { // 修改过时长
      if ([128, 143].includes(editLong)) {
        setFieldsValue({
          sDate: sDate ? moment(sDate) : null,
          long: editLong === 128 ? '1' : '2'
        })
        getLeaveOutEndDate(askType, sTime, moment(sDate).format('YYYY-MM-DD'), editLong)
      } else { // 其它时长
        setFieldsValue({
          sDate: sDate ? moment(sDate) : null,
          long: '3'
        })
        onchangeDate(editLong)
      }
    }
  }, [timeData])

  useEffect(() => {
    if (dateVal) {
      getLeaveOutEndDate(askType, sTime, moment(getFieldValue('sDate')).format('YYYY-MM-DD'), dateVal)
    }
  }, [dateVal])

  //获取请假结束日期
  const getLeaveOutEndDate = (ask, sTime, sDate, long) => {
    setTimeLoding(true)
    if (ask !== undefined && sTime !== undefined && sDate !== undefined && long !== undefined) {
      let timer = setTimeout(() => {
        api.getLeaveOutEndDate({
          ask,
          sTime: sTime === '上午' ? '上午 8:30' : '下午 13:30',
          sDate,
          long
        }).then(res => {
          setFieldsValue({
            eDate: moment(res.endDate)
          })
          setTimeLoding(false)
        }).catch(() => {
          setTimeLoding(false)
        })
        clearTimeout(timer)
      }, 500)
    } else {
      setFieldsValue({
        eDate: undefined
      })
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  }

  const onEditeLong = (e) => {
    if (e.target.value !== '3') {
      setDateVal(undefined)
      getLeaveOutEndDate(askType, sTime, moment(getFieldValue('sDate')).format('YYYY-MM-DD'), e.target.value === '1' ? 128 : 143)
    } else {
      setFieldsValue({
        eDate: undefined
      })
    }
  }

  const onchangeDate = (val) => {
    if (val) {
      setDateVal(val)
    } else {
      setDateVal(undefined)
    }
  }

  // 保存修改时长
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if(getFieldValue('long') == '3' && !dateVal) {
          message.warning("请输入其他时长中的天数")
          return
        }
        setSaveLoading(true)
        api.onLeaveOutEditTime({
          id: id,
          askType: askType,
          staffId: userId,
          sType: vals.long,
          sDate: moment(vals.sDate).format('YYYY-MM-DD'),
          eDate: moment(vals.eDate).format('YYYY-MM-DD'),
          long:  vals.long == '3' ? dateVal : vals.long === '1' ? 128 : 143,
        })
          .then(() => {
            setSaveLoading(false)
            setTimeVisible(false)
            message.success('修改时长成功')
            getLeaveOutList()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  return (
    <Spin spinning={timeloding}>
      <Form onSubmit={formSubmit} {...formItemLayout}>
        <FormItem label="起始日期">
          {getFieldDecorator('sDate')(
            <DatePicker placeholder="请选择起始日期" disabled allowClear={false} />
          )}
        </FormItem>
        <FormItem label="结束日期">
          {getFieldDecorator('eDate')(
            <DatePicker placeholder="请选择结束日期" disabled allowClear={false} />
          )}
        </FormItem>
        <FormItem label="请假时长">
          {getFieldDecorator('long', {
            rules: [{ required: true, message: '请选择请假时长' }]
          })(
            <Radio.Group onChange={onEditeLong}>
              <Radio value='1'>128</Radio>
              <Radio value='2'>143</Radio>
              <Radio value='3'>其他时长</Radio>
            </Radio.Group>
          )}
          {
            getFieldValue('long') == '3' ?
              <InputNumber min={1} value={dateVal} onChange={onchangeDate} parser={text => /^[1-9]\d*$/.test(text) ? text : undefined} /> : null
          }
        </FormItem>
        <BtnGroup style={{ marginTop: 20 }} onCancel={() => setTimeVisible(false)} loading={saveLoding} />
      </Form>
    </Spin>
  )
}

export default Form.create()(EditLong)