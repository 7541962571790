/**
 * 模块名称: 个人授权
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Alert,
  Icon,
  Drawer,
  Spin,
  Input,
  Button,
  Select,
  Modal,
  message
} from 'antd'
import AssignRoles from './module/AssignRoles'
import CorpFilter from '@/components/CorpFilter'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let companyId = ''
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  companyId: companyId
}
let storeFilterName = ''
let storeFilterContent = {}

const StaffManage = (props) => {
  const { history, match, location } = props

  const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form

  const [count, setCount] = useState(0)
  const [list, setList] = useState([])

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [drawerLoading, setDrawerLoading] = useState(true)

  const [roleType, setRoleType] = useState('全部')
  const [staffId, setStaffId] = useState(0)

  const [departmentList, setDepartmentList] = useState([])
  const [jobList, setJobList] = useState([])
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [roleList, setRoleList] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [roleTypeList, setRoleTypeList] = useState([])
  const [statusVal, setStatusVal] = useState(undefined)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)

  const [authList, setAuthList] = useState([])//按钮权限
  const search = parseSearch(props.location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    companyId = ''
    storeFilterName = ''
    storeFilterContent = {}
    getStaffRoleList()
    getPageAuth()
  }, [])

  //按钮权限
  const getPageAuth = () => {
    api.getPageAuth().then(list => {
      setAuthList(list)
    })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = data => {
    //console.log('onChangeCorp', data)
    setDepartmentList([])
    setFieldsValue({ 'departmentId': undefined })
    currentPage = 1
    if (data.length) {
      let arr = []
      data.forEach((item, index) => {
        arr.push(item.id)
      })
      companyId = arr.join(',')
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage,
        companyId: companyId
      }
    } else {
      companyId = ''
      searchParamsTmp = {
        limit: pageSize,
        page: currentPage
      }
    }
    history.replace(match.path)
    getStaffRoleList()
  }
  //点击重置
  const resetForm = () => {
    currentPage = 1
    companyId ? searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      companyId: companyId
    } : searchParamsTmp = {
      limit: pageSize,
      page: currentPage
    }
    history.replace(match.path)
    setRoleType('全部')
    resetFields()
    getStaffRoleList()
    setStatusVal(undefined)
    setStatusValName(undefined)
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getStaffRoleList()
  }

  //打开分配角色
  const onOperate = record => {
    //console.log(record)
    setVisible(true)
    setStaffId(record.staffId)
  }

  //根据职工获取角色列表
  const getStaffRoleList = () => {
    setLoading(true)
    if (companyId.length) searchParamsTmp.companyId = companyId
    api.getStaffRoleList(searchParamsTmp).then(res => {
      res.list && res.list.map((item, index, arr) => {
        arr[index].staffRolesJson = item.staffRoles.length ? item.staffRoles.join('，') : ''
        arr[index].jobRolesJson = item.jobRoles.length ? item.jobRoles.join('，') : ''
      })
      setCount(res.count)
      setList(res.list)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }
  //关闭角色授权弹窗
  const closeDrawer = () => {
    setVisible(false)
    setStaffId(0)
    setRoleType('全部')
  }
  //获取部门列表
  const getDepartmentList = () => {
    api.getSelectDepartmentList(companyId.length ? { limit: global.paramsLimit, companyId: companyId } : { limit: global.paramsLimit }).then(res => {
      setDepartmentList(res.list)
    })
  }
  //获取职位列表
  const getJobList = () => {
    api.getCommon({ type: 'staff_job_position' }).then(res => {
      setJobList(res)
    })
  }
  //获取角色类型
  const getRoleTypeList = () => {
    api.getRoleTypeList().then((res) => {
      setRoleTypeList(res.list)
    })
  }

  //修改角色类型
  const getRoleType = (val) => {
    setFieldsValue({ 'roleId': undefined })
    setRoleType(val)
    setRoleList([])
  }
  //获取角色列表
  const getRoleList = () => {
    api.getRoleList({
      type: roleType === '全部' ? 'all' : roleType
    }).then(data => {
      setRoleList(data.list)
    })
  }
  //数据转换
  const transData = vals => {
    searchParamsTmp = { ...searchParamsTmp, ...vals }
    for (let item in searchParamsTmp) {
      if (searchParamsTmp[item] === undefined || item === 'roleType') {
        delete searchParamsTmp[item]
      }
    }
  }
  //点击筛选
  const searchSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      //console.log(vals)
      if (vals.roleType && !vals.roleId) return message.warning('请选择角色后筛选')
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getStaffRoleList()
      }
    })

  }
  //选择保存方案
  const onChangeGetFilter = val => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(res => {
      setFilterContent(res.list)
    })
  }
  //选择方案
  const onFilterChecked = (item, val2) => {
    // let data = JSON.parse(item)
    setStatusVal(item)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[item].content
    searchParamsTmp.staffNo = data.staffNo
    searchParamsTmp.staffName = data.staffName
    searchParamsTmp.departmentId = data.departmentId
    searchParamsTmp.jobPosition = data.jobPosition
    searchParamsTmp.leaderId = data.leaderId
    setFieldsValue({
      staffNo: data.staffNo ? data.staffNo : undefined,
      staffName: data.staffName ? data.staffName : undefined,
      departmentId: data.departmentId ? data.departmentId : undefined,
      jobPosition: data.jobPosition ? data.jobPosition : undefined,
      leaderId: data.leaderId ? data.leaderId : undefined
    })
    getDepartmentList()
    getJobList()
  }

  // 筛选删除
  const onSearchDelete = id => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }
  //保存筛选点击确定
  const filterHandleOk = () => {
    validateFields((err, vals) => {
      storeFilterContent = vals
      for (let i in storeFilterContent) {//eslint-disable-line
        if (storeFilterContent[i] == undefined) {//eslint-disable-line
          storeFilterContent[i] = ''
        }
      }
      api.saveSearchStorage({
        name: storeFilterName,
        key: location.pathname,
        content: storeFilterContent
      }).then(() => {
        setFilterModelvisible(false)
        message.success('添加成功')
      })
    })
  }
  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }
  //修改筛选名称
  const onFilterInfoChange = e => {
    storeFilterName = e.target.value
  }

  return (
    <>
      <CorpFilter onChange={onChangeCorp} />
      <div className="search-role-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout} >
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>

            <FormItem label="公司用名">
              {getFieldDecorator('staffName')(<Input
                placeholder="请输入公司用名"
                style={{ width: 200 }}
              />)}
            </FormItem>
            <FormItem label="角色类型">
              {getFieldDecorator('roleType')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getRoleTypeList()}
                onChange={getRoleType}
                style={{ width: 200 }}
              >
                {
                  roleTypeList.map((item, index) => {
                    return <Option key={item.name}>{item.value}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="角色">
              {getFieldDecorator('roleId')(<Select
                placeholder={roleType !== '全部' ? '全部' : "请先选择角色类型"}
                onDropdownVisibleChange={bool => bool && getRoleList()}
                optionFilterProp="children"
                showSearch
                dropdownMatchSelectWidth={false}
                style={{ width: 200 }}
                disabled={roleType === '全部'}
              >
                {
                  roleList.map((item, index) => {
                    return <Option key={item.id} title={item.name}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="员工编号">
              {getFieldDecorator('staffNo')(<Input
                placeholder="请输入员工编号"
                style={{ width: 200 }}
              />)}
            </FormItem>
            <FormItem label="部门">
              {getFieldDecorator('departmentId')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getDepartmentList()}
                optionFilterProp="children"
                showSearch
                dropdownMatchSelectWidth={false}
                style={{ width: 200 }}
              >
                {
                  departmentList.map((v, index) => {
                    return !companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="职位">
              {getFieldDecorator('jobPosition')(<Select
                placeholder="全部"
                onDropdownVisibleChange={bool => bool && getJobList()}
                style={{ width: 200 }}
              >
                {
                  jobList.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="直接主管">
              {getFieldDecorator('leaderId')(<Input
                placeholder="请输入直接主管"
                style={{ width: 200 }}
              />)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" value={statusVal} onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked}>
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Button onClick={resetForm}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" onChange={onFilterInfoChange} />
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap"></div>
      <Alert className="corp-count" message={`总计：${count}条数据`} type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="staff-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
      >
        <Column title="ID" dataIndex="id" width={"8%"} />
        <Column title="员工编号" dataIndex="staffNo" width={"10%"} />
        <Column title="公司用名" dataIndex="staffName" width={"10%"} />
        <Column title="所属部门" dataIndex="departmentName" width={"10%"} />
        <Column title="职位" dataIndex="jobPositionName" width={"10%"} />
        <Column title="直接主管" dataIndex="leaderName" width={"10%"} />
        <Column title="职位角色" dataIndex="jobRolesJson" />
        <Column title="个人角色" dataIndex="staffRolesJson" width={"20%"} />
        <Column title="操作" key="set" width={80} fixed="right" render={(text, record) => (
          <>
            <Auth auths={authList} code="role-allocation-role">
              <Icon type="user-add" style={{ color: '#1890ff' }} className="operate-icon" onClick={() => onOperate(record)} />
            </Auth>
          </>
        )} />
      </Table>
      <Drawer
        title="分配角色"
        width={600}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <AssignRoles
            setVisible={setVisible}
            roleType={roleType}
            setRoleType={setRoleType}
            sId={staffId}
            setUseId={setStaffId}
            routee="staff"
            getStaffRoleList={getStaffRoleList}
            setDrawerLoading={setDrawerLoading}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default Form.create()(StaffManage)