/**
 * 模块名称: 添加子角色
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Select,
	Form,
	Input,
	Checkbox,
	Button,
	Tooltip,
	message,
	TreeSelect,
	Row,
	Col
} from 'antd'

const { SHOW_PARENT } = TreeSelect

const FormItem = Form.Item
const { Option } = Select

let companies = null

let allCorpList = [], allAreaPortList = []

const AddSonRole = (props) => {
	const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = props.form

	const { corpList, setVisible, sonRoleInfo, getRoleList, setDrawerLoading } = props

	const [projectOptions, setProjectOptions] = useState([])
	const [areaProjects, setAreaProjects] = useState([])
	const [areaPortList, setAreaPortList] = useState([])

	useEffect(() => {
		initInfoByPid()
	}, [])

	allCorpList = [...corpList]

	const [areaBranchTip, areaProjectTip, areaPortTip] = [
		"该选项将影响筛选项以及列表数据仅能查看已选择公司的数据",
		"该选项影响审批的项目范围",
		"该选项影响审批的供应商范围"
	]

	const initInfoByPid = async () => {
		//这个是获取全部端口范围
		await api.getSysSupplierList({ isValidate: 1, page: 1, limit: global.paramsLimit }).then(res => {
			let arr = res.list.map((item) => ({ value: item.id, label: item.name }))
			setAreaPortList(arr)
			allAreaPortList = [...arr]
		})
		if (sonRoleInfo.pid !== 0) {
			await onGetRoleById(sonRoleInfo.id)
		}
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 6 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 18 },
		}
	}

	const getCompanyById = id => {
		if (!companies) {
			companies = {}
			for (let i = 0; i < allCorpList.length; i++) {
				companies[allCorpList[i].id] = allCorpList[i]
			}
		}

		return companies[id]
	}


	const randerProject = (companIds, foo) => {
		const treeData = companIds.map(companId => {
			companies = null
			const company = getCompanyById(companId)
			if (company) {
				const disabled = company.projectRangeList && company.projectRangeList.length === 0
				return {
					title: company.name,
					value: '0-' + company.id,
					key: '0-' + company.id,
					disabled,
					children: company.projectRangeList ? company.projectRangeList.map(project => {
						return {
							title: project.name + '（' + company.name + '）',
							value: '0-' + company.id + '-' + project.id,
							key: '0-' + company.id + '-' + project.id
						}
					}) : []
				}
			} else {
				return null
			}
		})
		setProjectOptions(treeData)
		foo && foo(treeData)
	}

	const addSonRoleSubmit = e => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				let params = { ...vals }

				params.areaProject = {}
				for (let i = 0; i < areaProjects.length; i++) {
					const tmp = areaProjects[i].split('-')
					if (!params.areaProject[tmp[1]]) {
						params.areaProject[tmp[1]] = []
					}
					if (tmp[2]) {
						params.areaProject[tmp[1]].push(+tmp[2])
					} else {
						getCompanyById(tmp[1]).projectRangeList.map((item) => {
							params.areaProject[tmp[1]].push(+item.id)
						})
					}

				}

				params.type = sonRoleInfo.type ? sonRoleInfo.type : 'approval'
				if (sonRoleInfo.pid !== 0) {
					params.id = sonRoleInfo.id
				} else {
					params.pid = sonRoleInfo.id
				}
				roleAddChildRole(params)

			} else {
				for (let i in err) {
					message.error(err[i].errors[0].message)
				}
			}

		})
	}
	//添加子角色//修改子角色
	const roleAddChildRole = (vals) => {
		if (sonRoleInfo.pid !== 0) {//修改子角色
			api.onRoleEditChildRole(vals).then(res => {
				message.success('修改成功')
				getRoleList()
				setVisible(false)
			})
		} else {//添加子角色
			api.onRoleAddChildRole(vals).then(res => {
				message.success('分配成功')
				getRoleList()
				setVisible(false)
			})
		}

	}

	//根据角色ID查询角色信息
	const onGetRoleById = (id) => {
		setDrawerLoading(true)
		api.onGetRoleById({ id: id }).then(res => {
			let obj = {}
			if (res.childRoleInfo) {
				if (res.childRoleInfo.areaBranch.length) {
					obj.areaBranch = res.childRoleInfo.areaBranch.split(',')
				} else {
					obj.areaBranch = []
				}
			}
			const areaProject = res.childRoleInfo.areaProject ? eval('(' + res.childRoleInfo.areaProject + ')') : null
			obj.areaPort = res.childRoleInfo.areaPort ? res.childRoleInfo.areaPort.split(',') : []
			let reg = /^\d+$/
			//字符串转数字
			for (let i in obj) {//eslint-disable-line
				obj[i].forEach((item, index, arr) => {
					arr[index] = reg.test(item) ? item - 0 : item
				})
			}

			const optionVals = []
			for (let i in areaProject) {
				for (let j = 0; j < areaProject[i].length; j++) {
					areaProject[i][j] = `0-${i}-${areaProject[i][j]}`
				}
			}
			obj.areaBranch.length && randerProject(obj.areaBranch, tree => {
				let arr = []
				for (let i = 0, len = tree.length; i < len; i++) {
					for (let j = 0, lenJ = tree[i].children.length; j < lenJ; j++) {
						arr.push(tree[i].children[j].value)
					}
				}
				for (let i in areaProject) {
					for (let j = 0; j < areaProject[i].length; j++) {
						if (arr.includes(areaProject[i][j])) optionVals.push(areaProject[i][j])
					}
				}
				setAreaProjects(optionVals)
			})

			obj.areaBranch.length && setFieldsValue({
				'areaBranch': obj.areaBranch
			})
			// console.log(obj, allAreaPortList)
			let arr = allAreaPortList.reduce((prev, curr) => obj.areaPort.includes(curr.value) ? [...prev, curr.value] : prev, [])
			setFieldsValue({
				'areaPort': arr
			})
			api.getSysSupplierList({ isValidate: 1, page: 1, limit: global.paramsLimit, projectIds: optionVals.map(item => item.split('-')[2]) }).then(res => {
				let data = res.list.map(item => ({ value: item.id, label: item.name }))
				setAreaPortList(data)
			})

			setDrawerLoading(false)
		}).catch(() => {
			setDrawerLoading(false)
		})
	}

	const resetForm = () => {
		setVisible(false)
		resetFields()
	}

	//选择公司点击
	const onChangeCheckCorp = data => {
		const newProjectVals = areaProjects.filter((val) => data.includes(val.split('-')[1] - 0))
		randerProject(data)
		setAreaProjects(newProjectVals)
		setFieldsValue({
			'areaProject': newProjectVals
		})
	}
	//选择项目范围点击
	const onChangeCheckPro = p => {
		setAreaProjects(p)
		let arr = [...p]
		projectOptions.map(item => {
			if (p.includes(item.value)) {
				item.children && item.children.map(itemm => arr.push(itemm.value))
			}
		})
		api.getSysSupplierList({
			isValidate: 1,
			page: 1,
			limit: global.paramsLimit,
			projectIds: arr.map(item => item.split('-')[2]).filter(item => item)
		}).then((res) => {
			setAreaPortList(res.list.map(item => ({ value: item.id, label: item.name })))
		})
		/*setFieldsValue({
			'areaPort': []
		})*/
	}

	const tProps = {
		showSearch: true,
		treeNodeFilterProp: "title",
		treeData: projectOptions,
		onChange: onChangeCheckPro,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		placeholder: '请选择项目',
		getPopupContainer: triggerNode => {
			// console.log(triggerNode)
			return triggerNode.parentNode
		},
		dropdownStyle: {
			maxHeight: 200,
			overflow: 'auto'
		},
		style: {
			width: '82%',
			marginRight: '15px',
		}
	}

	//全选供应商范围
	const selCorpAll = () => {
		setFieldsValue({
			'areaPort': areaPortList.reduce((prev, curr) => [...prev, curr.value], [])
		})
	}

	return (
		<>
			<Form className="addSon-role-form" onSubmit={addSonRoleSubmit} {...formItemLayout}>
				<FormItem label="角色名称">
					{getFieldDecorator('name', {
						initialValue: sonRoleInfo.pid === 0 ? '' : sonRoleInfo.name,
						rules: [{ required: true, message: '请输入角色名称' }]
					})(<Input placeholder="请输入角色名称" />)}
				</FormItem>
				<FormItem required label={<Tooltip title={areaBranchTip} placement="bottomLeft">公司范围</Tooltip>}>
					{/* <Checkbox
						indeterminate={indeterminate}
						onChange={onChangeCheckCorpAll}
						checked={checkAll}
					>全部</Checkbox> */}
					{getFieldDecorator('areaBranch', {
						rules: [{ required: true, message: '请选择公司范围' }]
					})(<Checkbox.Group
						options={corpList}
						onChange={onChangeCheckCorp}
					>
					</Checkbox.Group>)}

				</FormItem>


				<FormItem label={<Tooltip title={areaProjectTip} placement="bottomLeft">项目范围</Tooltip>}>
					{getFieldDecorator('areaProject', {
						initialValue: areaProjects,
						rules: [{ required: false, message: '请选择项目范围' }]
					})(<TreeSelect {...tProps} />)}
					<Button type="primary" onClick={() => setFieldsValue({ areaProject: [] })}>清空</Button>
				</FormItem>

				<FormItem label={<Tooltip title={areaPortTip} placement="bottomLeft">供应商范围</Tooltip>}>
					<Row gutter={2}>
						<Col span={18}>
							{getFieldDecorator('areaPort', {
								rules: [{ required: false, message: '请选择供应商' }]
							})(
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									mode="multiple"
									style={{ width: '95%' }}
									placeholder="请选择供应商"
								>
									{
										(() => {
											return areaPortList.map((item) => (
												<Option key={item.value} value={item.value}>{item.label}</Option>
											))
										})()
									}
								</Select>
							)}
						</Col>
						<Col span={3}>
							<Button type="primary" onClick={selCorpAll}>全选</Button>
						</Col>
						<Col span={3}>
							<Button type="primary" onClick={() => {
								setFieldsValue({ 'areaPort': [] })
							}}>清空</Button>
						</Col>
					</Row>
				</FormItem>
				<FormItem className="btns addSon-role-btns" label=" " colon={false}>
					<Button onClick={resetForm}>取消</Button>
					<Button className="submit" type="primary" htmlType="submit">确定</Button>
				</FormItem>
			</Form>
		</>
	)
}

export default Form.create()(AddSonRole)