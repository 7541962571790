/**
 * 模块名称: 职位授权
 * @author lids@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  Icon,
  Drawer,
  Spin,
  Alert,
  Checkbox,
  Select,
  message
} from 'antd'
import CorpMore from 'components/CorpMore'
import AssignRoles from './module/AssignRoles'
import Auth from '@/components/AuthMiddleware'
import { parseSearch } from '@/utils'

let pageSize = 10
let currentPage = 1
let companyId = 'all'
// let nameId = ''
let authList = []//按钮权限
let jobListIds = []
const { Option } = Select

const JobManage = (props) => {
  const { history, match, location } = props
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [columnsData, setColumnsData] = useState([])
  const [loading, setLoading] = useState(true)

  const [drawerLoading, setDrawerLoading] = useState(false)
  const [deptId, setDeptId] = useState(0)
  const [jobId, setJobId] = useState(null)
  const [roleType, setRoleType] = useState('全部')
  const [visible, setVisible] = useState(false)
  const [nameId, setNameId] = useState('')
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([])
  const [depListIds, setDepListIds] = useState([])
  const [depList, setDepList] = useState([])
  const [depType, setDepType] = useState(undefined)
  const [openBy, setOpenBy] = useState(null)
  const [opType, setOpType] = useState(null)
  const [assiDrawerName, setAssiDrawerName] = useState('分配角色')

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    companyId = 'all'
    authList = []//按钮权限
    getPageAuth(() => {
      getAllDepartmentJobRoles()
    })
    api.getCommon({ type: 'department_type' }).then(data => setDepList([{ id: 0, name: '全部' }].concat(data))) //部门
  }, [])

  //按钮权限
  const getPageAuth = (cb) => {
    api.getPageAuth().then(list => {
      authList = list
      cb && cb()
    })
  }

  //搜索职位点击
  const onSearch = () => {
    currentPage = 1
    history.replace(match.path)
    getAllDepartmentJobRoles()
    setSelectedRowKeysData([])
  }
  //重置
  const resetForm = () => {
    setNameId('')
    setDepType(undefined)
    currentPage = 1
    history.replace(match.path)
    getRolesList({
      limit: pageSize,
      page: currentPage,
      companyId: companyId
    })
    setSelectedRowKeysData([])
  }
  const onChangeIdRef = e => {
    setNameId(e.target.value)
  }
  //公司筛选
  const onChangeCorp = data => {
    //console.log('onChangeCorp', data)
    setSelectedRowKeysData([])
    if (data.length) {
      let arr = []
      data.forEach(item => {
        arr.push(item.id)
      })
      companyId = arr.join(',')
    } else {
      companyId = 'all'
    }
    currentPage = 1
    history.replace(match.path)
    getAllDepartmentJobRoles()
  }
  //职位授权列表
  const getAllDepartmentJobRoles = () => {
    let params = {
      limit: pageSize,
      page: currentPage,
      name: nameId,
      companyId: companyId
    }
    if (depType > 0) params.departmentType = depType
    getRolesList(params)
  }
  const getRolesList = (params) => {
    let tmpColumnsData = []
    api.getAllDepartmentJobRoles(params).then(res => {
      setCount(res.count)
      res.allIds && setDepListIds(res.allIds)
      let jobs = res.jobs, list = res.list
      for (let i in jobs) {//eslint-disable-line
        tmpColumnsData.push({
          title: () => {
            return jobs[i] !== '总经理' ? <div><Checkbox onClick={e => getDepartment(e, i)} /> {jobs[i]}</div> : jobs[i]
          },
          key: i,
          dataIndex: i,
          render: (item, record) => {//eslint-disable-line
            if (item === '未分配角色') {
              return <Auth auths={authList} code="role-allocation-role">
                <Icon
                  style={{ color: 'blue', fontSize: '20px', cursor: 'pointer' }}
                  type='user'
                  onClick={() => openRoleDrawer(item, record, i)}
                />
              </Auth>
            } else {
              return <Auth auths={authList} code="role-allocation-role">
                <Icon
                  style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}
                  type='user'
                  onClick={() => openRoleDrawer(item, record, i)}
                />
              </Auth>
            }
          }
        })
      }
      tmpColumnsData.unshift({
        title: '公司+部门名称',
        key: 'id',
        dataIndex: 'name'
      })
      setColumnsData(tmpColumnsData)
      setList(list)
      setLoading(false)
    })
  }
  //选择部门chekbox
  const getDepartment = (e, id) => {
    if (e.target.checked) {
      jobListIds.push(id)
    } else {
      jobListIds = jobListIds.filter(item => item !== id)
    }
  }
  const onChangeDep = e => {
    setDepType(e)
  }
  //授权
  const onGiveAuth = type => {
    // console.log(jobListIds, selectedRowKeysData)
    if (!jobListIds.length) return message.warning('请选择至少一个职位')
    if (!selectedRowKeysData.length) return message.warning('请选择至少一个部门')
    setVisible(true)
    setJobId(null)
    setOpenBy('multiple')
    if (type == 'add') setAssiDrawerName('分配角色')
    if (type == 'sub') setAssiDrawerName('取消分配角色')
    setOpType(type)
  }
  //点击分配角色
  const openRoleDrawer = (item, record, index) => {
    //console.log(item, record, index)
    setVisible(true)
    setDeptId(record.id)
    setJobId(index)
    setOpenBy('single')
    setAssiDrawerName('分配角色')
    setOpType(null)
  }
  //关闭角色授权弹窗
  const closeDrawer = () => {
    setVisible(false)
    setDeptId(0)
    setJobId(null)
    setRoleType('全部')
    setOpenBy(null)
    setOpType(null)
    setAssiDrawerName('分配角色')
  }
  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getAllDepartmentJobRoles()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (selected) {
        setSelectedRowKeysData([...new Set([...selectedRowKeysData, record.id])])
      } else {
        setSelectedRowKeysData(selectedRowKeysData.filter(item => item !== record.id))
      }
    },
    //全选
    onSelectAll: (selected, selectedRows, changeRows) => {
      // console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setSelectedRowKeysData([...new Set([...selectedRowKeysData, ...arr])])
      } else {
        setSelectedRowKeysData(selectedRowKeysData.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: selectedRowKeysData
  }

  //反选
  const onReverse = () => {
    setSelectedRowKeysData(depListIds.filter(item => !selectedRowKeysData.includes(item)))
  }
  //全选
  const onAllelection = () => {
    setSelectedRowKeysData(depListIds)
  }

  return (
    <>
      <CorpMore onChange={onChangeCorp} />
      <div className="filter-wrap">
        <h4 className="title">其它选项：</h4>
        <div className="filter-item">
          <label htmlFor="" style={{ width: '90px' }}>部门名称</label>
          <input placeholder="不限" value={nameId} onChange={onChangeIdRef} className="ant-input" type="text" />
        </div>
        <div className="filter-item">
          <label htmlFor="" style={{ width: '60px' }}>部门类型</label>
          <Select
            placeholder="请选择"
            onChange={onChangeDep}
            value={depType}
            style={{ width: '200px' }}
          >
            {
              depList.length && depList.map(item => {
                return <Option key={item.id}>{item.name}</Option>
              })
            }
          </Select>
        </div>
        <Button onClick={resetForm} style={{ marginLeft: "10px" }}>重置</Button>
        <Button type="primary" onClick={onSearch} style={{ marginLeft: "10px" }}>查询</Button>
        <Auth auths={authList} code="role-allocation-role">
          <Button type="primary" onClick={() => onGiveAuth('add')} style={{ marginLeft: "10px" }}>授权</Button>
        </Auth>
        <Auth auths={authList} code="role-allocation-role">
          <Button type="primary" onClick={() => onGiveAuth('sub')} style={{ marginLeft: "10px" }}>取消授权</Button>
        </Auth>
      </div>
      <div className="add-wrap"></div>
      <div className="line"></div>
      <div className="white-line"></div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{selectedRowKeysData.length}</span> 项</span>
          {selectedRowKeysData.length !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {selectedRowKeysData.length == 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          {selectedRowKeysData.length !== depListIds.length && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>}
          {selectedRowKeysData.length == depListIds.length && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>全选</span>}
        </>
      } type="info" showIcon />
      <Table
        size="middle"
        dataSource={list}
        rowKey="id"
        className="job-table"
        columns={columnsData}
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
      </Table>
      <Drawer
        title={assiDrawerName}
        width={600}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose={true}
      >
        <Spin spinning={drawerLoading}>
          <AssignRoles
            setVisible={setVisible}
            roleType={roleType}
            setRoleType={setRoleType}
            sId={deptId}
            jobId={jobId}
            setDeptId={setDeptId}
            setUseId={setJobId}
            routee="job"
            getStaffRoleList={getAllDepartmentJobRoles}
            setDrawerLoading={setDrawerLoading}
            jobIds={jobListIds}
            depIds={selectedRowKeysData}
            openBy={openBy}
            setSelectedRowKeysData={setSelectedRowKeysData}
            opType={opType}
          />
        </Spin>
      </Drawer>
    </>
  )
}

export default JobManage